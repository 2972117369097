(function () {
    'use strict';

    angular
        .module('elogbooks.common.webhooks')
        .controller('WebhooksDetailsInfoController', WebhooksDetailsInfoController);

    WebhooksDetailsInfoController.$inject = ['config', 'webhookResponse', 'serviceProviderResponse'];

    function WebhooksDetailsInfoController (config, webhookResponse, serviceProviderResponse) {
        var vm = this;
        vm.webhook = webhookResponse;
        vm.type = 'view';

        if (webhookResponse == null) {
            vm.type = 'list';
            vm.webhook = {
                hasResponse: false,
                canCreate: serviceProviderResponse.getLink('add-webhook')
            };
        } else if (webhookResponse.id) {
            vm.webhook.hasResponse = true;
        }

        vm.config = config;

        vm.noBorder = vm.config.noBorder || false;
    }
})();
