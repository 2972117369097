angular.module('elogbooksServices').service('dashboardService', function (
    lodash,
    apiClient,
    userManager,
    $window,
    $stateParams,
    elbSettings,
    $translate,
    modulesService,
    $q
) {

    'use strict';

    var self = this;
        self.getStatistics = getStatistics;
        self.getFilterParams = getFilterParams;
        self.statToggled = statToggled;
        self.applyUserPreferences = applyUserPreferences;
        self.setStatProperties = setStatProperties;
        self.reset = reset;
        this.applyFinanceFilters = applyFinanceFilters;
        self.showStatistics = false;
    var openIntegrationActionsModules = ['meridian_enabled', 'riskwise_enabled', 'alcumus_enabled', 'qcompliance_enabled', 'optima_enabled', 'propertyplus_enabled'];

    var initialStats = [
        {
            route: "escalations",
            params: angular.extend({}, getFilterParams(), {
                active: true,
                order: "createdAt",
                limit: 4,
            }),
            enabled: false,
            type: "escalations",
            responseKey: "escalations",
            dashboardWidget: "escalations",
            header: "Loading",
            headerIcon: "fa-cog",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-reactive-overdue-attendance",
            data: false,
            enabled: false,
            dashboardWidget: "reactive_jobs_overdue_attendance",
            type: "job_reactive_overdue_attendance",
            header: "JOBS_REACTIVE_OVERDUE_ATTENDANCE",
            headerIcon: "fa-exclamation-circle",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-reactive-overdue-completion",
            data: false,
            enabled: false,
            dashboardWidget: "reactive_jobs_overdue_completion",
            type: "job_reactive_overdue_completion",
            header: "JOBS_REACTIVE_OVERDUE_COMPLETION",
            headerIcon: "fa-exclamation-circle",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-planned-overdue-completion",
            data: false,
            enabled: false,
            dashboardWidget: "planned_jobs_overdue_completion",
            type: "job_planned_overdue_completion",
            header: "JOBS_PLANNED_OVERDUE_COMPLETION",
            headerIcon: "fa-clock-o",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-open-flagged-priority-jobs",
            data: false,
            enabled: false,
            type: "open_flagged_priority_jobs",
            responseKey: "priorities",
            dashboardWidget: "open_flagged_priority_jobs",
            header: "OPEN_FLAGGED_PRIORITY_JOBS",
            headerIcon: "fa-exclamation-circle",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-awaiting-client-review",
            data: false,
            enabled: false,
            dashboardWidget: "awaiting_client_review",
            type: "awaiting_client_review",
            header: "AWAITING_CLIENT_REVIEW",
            headerIcon: "fa-clipboard",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-overdue-paperwork",
            data: false,
            enabled: false,
            dashboardWidget: "overdue_paperwork",
            type: "overdue_paperwork",
            header: "OVERDUE_PAPERWORK",
            headerIcon: "fa-clipboard",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-updates-due",
            data: false,
            enabled: false,
            dashboardWidget: "updates_due",
            type: "updates_due",
            header: "UPDATES_DUE",
            headerIcon: "fa-exclamation-circle",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-pending-acknowledgement",
            data: false,
            enabled: false,
            dashboardWidget: "pending_acknowledgement",
            type: "job_pending_acknowledgement",
            header: "JOB_STATUS_ASSIGNMENT_PENDING",
            headerIcon: "fa-thumbs-o-up",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-outstanding-extension-requests",
            data: false,
            enabled: false,
            dashboardWidget: "outstanding_extension_requests",
            type: "outstanding_extension_requests",
            header: "OUTSTANDING_EXTENSION_REQUESTS",
            headerIcon: "fa-check-circle",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-approvals-required",
            data: false,
            enabled: false,
            dashboardWidget: "outstanding_approvals",
            type: "approvals_required",
            header: "APPROVALS_REQUIRED",
            headerIcon: "fa-check-circle",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-outstanding-extension-approvals",
            data: false,
            enabled: false,
            dashboardWidget: "outstanding_extension_approvals",
            type: "outstanding_extension_approvals",
            header: "OUTSTANDING_EXTENSION_APPROVALS",
            headerIcon: "fa-check-circle",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-surveys",
            data: false,
            enabled: false,
            dashboardWidget: "outstanding_kpi_surveys",
            type: "surveys",
            header: "OUTSTANDING_SURVEYS",
            headerIcon: "fa-check-square-o",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-outstanding-remedials",
            params: angular.extend({}, getFilterParams(), { remedial: "true" }),
            data: false,
            enabled: false,
            dashboardWidget: "outstanding_remedials",
            type: "outstanding_remedials",
            header: "Outstanding Remedials",
            headerIcon: "fa-pencil-square-o",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-open-actions",
            data: false,
            enabled: false,
            dashboardWidget: "open_actions",
            type: "action_open",
            header: "ACTIONS_OPEN",
            headerIcon: "fa-flag",
            wrapperClass: ""
        },
        {
            route: "dashboard-statistics-ppm-requiring-approval-sp",
            params: angular.extend({}, getFilterParams(), { status: "active" }),
            data: false,
            enabled: false,
            type: "planned_requiring_approval_sp",
            responseKey: "sitetasksrequiringapproval",
            dashboardWidget: "planned_jobs_requiring_approval_sp",
            header: "PLANNED_JOBS_REQUIRING_APPROVAL_SP",
            headerIcon: "fa-calendar-check-o",
            wrapperClass: "dashboard-escalations"
        },
        {
            route: "dashboard-statistics-ppm-requiring-approval",
            params: angular.extend({}, getFilterParams(), { status: "active" }),
            data: false,
            enabled: false,
            type: "planned_requiring_approval",
            responseKey: "sitetasksrequiringapproval",
            dashboardWidget: "planned_jobs_requiring_approval",
            header: "SITES_WITH_PPM_REQUIRING_APPROVAL",
            headerIcon: "fa-calendar-check-o",
            wrapperClass: "dashboard-escalations"
        },
        {
            route: "dashboard-statistics-job-requisites-requiring-approval",
            data: false,
            enabled: false,
            dashboardWidget: "job_requisites_requiring_approval",
            type: "job_requisites_requiring_approval",
            header: "JOB_REQUISITES_REQUIRING_APPROVAL",
            headerIcon: "fa-file-text-o",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-open-integration-actions",
            data: false,
            enabled: false,
            dashboardWidget: "open_integration_actions",
            header: "Loading",
            headerIcon: "fa-flag",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-p2p-jobs",
            data: false,
            enabled: false,
            dashboardWidget: "p2p_jobs",
            moduleName: "p2p_enabled",
            type: "job_p2p",
            header: "P2P_JOBS",
            headerIcon: "fa-clipboard",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-job-values",
            data: false,
            enabled: false,
            dashboardWidget: "job_values",
            moduleName: "job_value_enabled",
            type: "job_values",
            header: "JOB_VALUES",
            headerIcon: "fa-clipboard",
            wrapperClass: "dashboard-surveys"
        },
        {
            route: "dashboard-statistics-contract-timelines",
            data: false,
            enabled: false,
            dashboardWidget: "contract_timelines",
            type: "contracts_misc",
            header: "CONTRACT_TIMELINES",
            headerIcon: "fa-file-text-o",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-contract-renewals",
            data: false,
            enabled: false,
            dashboardWidget: "contract_renewals",
            type: "contracts_renewals",
            header: "CONTRACT_RENEWALS",
            headerIcon: "fa-file-text-o",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-contract-approvals",
            data: false,
            enabled: false,
            dashboardWidget: "contract_approvals",
            type: "contracts_awaiting_approval",
            header: "CONTRACT_APPROVALS",
            headerIcon: "fa-file-text-o",
            wrapperClass: "dashboard-updates"
        },
        {
            route: "dashboard-statistics-asset-score-type-1",
            customData: [],
            header: "ASSET_SCORE",
            headerIcon: 'fa-cubes',
            data: false,
            enabled: false,
            dashboardWidget: "asset_score_type_1",
        },
        {
            route: "dashboard-statistics-asset-score-type-2",
            customData: null,
            data: false,
            header: "ASSET_SCORE",
            headerIcon: 'fa-cubes',
            enabled: false,
            dashboardWidget: "asset_score_type_2",
        },
        {
            route: "dashboard-statistics-asset-score-type-3",
            customData: null,
            data: false,
            header: "ASSET_SCORE",
            headerIcon: 'fa-cubes',
            enabled: false,
            dashboardWidget: "asset_score_type_3",
        },
        {
            route: "dashboard-statistics-performance-statistics",
            data: false,
            enabled: false,
            dashboardWidget: "performance_statistics",
            type: "performance_statistics",
            header: "PERFORMANCE_STATISTICS",
            headerIcon: "fa-line-chart",
            wrapperClass: ""
        }
    ];

    // process asset_score_type_[X]_[custom] to translated 'Asset Score Type - ' plus non translated [custom]
    this.translateAssetScoreType = function (label, transType) {
        var translation;
        var translationDetail;

        if (label.indexOf('asset_score_type_') > -1) {
            var index = 1;
            if (label.indexOf('asset_score_type_2') > -1) {
                index = 2;
            } else if (label.indexOf('asset_score_type_3') > -1) {
                index = 3;
            }

            translationDetail = translation = $translate.instant('ASSET_SCORE') + ' - ' + label.substring(label.indexOf(index) + 2);
        } else {
            translation = $translate.instant(label.toUpperCase());
            translationDetail = $translate.instant(label.toUpperCase() + '_DETAIL');
        }

        if (transType == 'detail') {
            return translationDetail;
        }

        return translation;
    };

    this.getAllTranslated = function () {
        var _dashboardWidgets = [];
        angular.forEach(initialStats, function (value) {

            if (value.moduleName) {
                if (!elbSettings.getSetting(value.moduleName)) {
                    _dashboardWidgets.push({
                        label: value.dashboardWidget,
                        status: 'disabled'
                    });

                    return;
                } else if (!elbSettings.getSetting(value.moduleName).value) {
                    _dashboardWidgets.push({
                        label: value.dashboardWidget,
                        status: 'disabled'
                    });

                    return;
                }
            }

            if (value.dashboardWidget === 'open_integration_actions' && !isIntegrationActionsEnabled()) {
                _dashboardWidgets.push({
                    label: 'open_integration_actions',
                    status: 'disabled'
                });

                return;
            }

            if (value.dashboardWidget.indexOf('asset_score_type_') > -1) {
                var settingName = elbSettings.getSetting('default_asset_score_types').value;
                var key = null;

                if (value.dashboardWidget === 'asset_score_type_1') {
                    key = 1;
                }

                if (value.dashboardWidget === 'asset_score_type_2') {
                    key = 2;
                }

                if (value.dashboardWidget === 'asset_score_type_3') {
                    key = 3;
                }

                if (key && settingName[key - 1] && settingName[key - 1].name) {
                    var name = 'asset_score_type_' + key + '_' + settingName[key - 1].name;
                    _dashboardWidgets.push({
                        label: name,
                        translation: self.translateAssetScoreType(name),
                        description: self.translateAssetScoreType(name, 'detail'),
                    });
                }

                return;
            }

            _dashboardWidgets.push({
                label: value.dashboardWidget,
                translation: $translate.instant(value.dashboardWidget.toUpperCase()),
                description: $translate.instant(value.dashboardWidget.toUpperCase() + '_DETAIL')
            });
        });

        return _dashboardWidgets;
    };

    function applyFinanceFilters(widgets) {
        self.dashboardStats =  angular.extend({}, widgets);
    }

    function reset() {
        self.dashboardStats = initInitialStats();
        self.showStatistics = false;
    }

    function initInitialStats() {
        return [
            {
                route: "escalations",
                params: angular.extend({}, getFilterParams(), {
                    active: true,
                    order: "createdAt",
                    limit: 4,
                }),
                enabled: false,
                type: "escalations",
                responseKey: "escalations",
                dashboardWidget: "escalations",
                header: "Loading",
                headerIcon: "fa-cog",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-reactive-overdue-attendance",
                data: false,
                enabled: false,
                dashboardWidget: "reactive_jobs_overdue_attendance",
                type: "job_reactive_overdue_attendance",
                header: "JOBS_REACTIVE_OVERDUE_ATTENDANCE",
                headerIcon: "fa-exclamation-circle",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-reactive-overdue-completion",
                data: false,
                enabled: false,
                dashboardWidget: "reactive_jobs_overdue_completion",
                type: "job_reactive_overdue_completion",
                header: "JOBS_REACTIVE_OVERDUE_COMPLETION",
                headerIcon: "fa-exclamation-circle",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-planned-overdue-completion",
                data: false,
                enabled: false,
                dashboardWidget: "planned_jobs_overdue_completion",
                type: "job_planned_overdue_completion",
                header: "JOBS_PLANNED_OVERDUE_COMPLETION",
                headerIcon: "fa-clock-o",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-open-flagged-priority-jobs",
                data: false,
                enabled: false,
                type: "open_flagged_priority_jobs",
                responseKey: "priorities",
                dashboardWidget: "open_flagged_priority_jobs",
                header: "OPEN_FLAGGED_PRIORITY_JOBS",
                headerIcon: "fa-exclamation-circle",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-awaiting-client-review",
                data: false,
                enabled: false,
                dashboardWidget: "awaiting_client_review",
                type: "awaiting_client_review",
                header: "AWAITING_CLIENT_REVIEW",
                headerIcon: "fa-clipboard",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-overdue-paperwork",
                data: false,
                enabled: false,
                dashboardWidget: "overdue_paperwork",
                type: "overdue_paperwork",
                header: "OVERDUE_PAPERWORK",
                headerIcon: "fa-clipboard",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-updates-due",
                data: false,
                enabled: false,
                dashboardWidget: "updates_due",
                type: "updates_due",
                header: "UPDATES_DUE",
                headerIcon: "fa-exclamation-circle",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-pending-acknowledgement",
                data: false,
                enabled: false,
                dashboardWidget: "pending_acknowledgement",
                type: "job_pending_acknowledgement",
                header: "JOB_STATUS_ASSIGNMENT_PENDING",
                headerIcon: "fa-thumbs-o-up",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-outstanding-extension-requests",
                data: false,
                enabled: false,
                dashboardWidget: "outstanding_extension_requests",
                type: "outstanding_extension_requests",
                header: "OUTSTANDING_EXTENSION_REQUESTS",
                headerIcon: "fa-check-circle",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-approvals-required",
                data: false,
                enabled: false,
                dashboardWidget: "outstanding_approvals",
                type: "approvals_required",
                header: "APPROVALS_REQUIRED",
                headerIcon: "fa-check-circle",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-outstanding-extension-approvals",
                data: false,
                enabled: false,
                dashboardWidget: "outstanding_extension_approvals",
                type: "outstanding_extension_approvals",
                header: "OUTSTANDING_EXTENSION_APPROVALS",
                headerIcon: "fa-check-circle",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-surveys",
                data: false,
                enabled: false,
                dashboardWidget: "outstanding_kpi_surveys",
                type: "surveys",
                header: "OUTSTANDING_SURVEYS",
                headerIcon: "fa-check-square-o",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-outstanding-remedials",
                params: angular.extend({}, getFilterParams(), { remedial: "true" }),
                data: false,
                enabled: false,
                dashboardWidget: "outstanding_remedials",
                type: "outstanding_remedials",
                header: "Outstanding Remedials",
                headerIcon: "fa-pencil-square-o",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-open-actions",
                data: false,
                enabled: false,
                dashboardWidget: "open_actions",
                type: "action_open",
                header: "ACTIONS_OPEN",
                headerIcon: "fa-flag",
                wrapperClass: ""
            },
            {
                route: "dashboard-statistics-ppm-requiring-approval-sp",
                params: angular.extend({}, getFilterParams(), { status: "active" }),
                data: false,
                enabled: false,
                type: "planned_requiring_approval_sp",
                responseKey: "sitetasksrequiringapproval",
                dashboardWidget: "planned_jobs_requiring_approval_sp",
                header: "PLANNED_JOBS_REQUIRING_APPROVAL_SP",
                headerIcon: "fa-calendar-check-o",
                wrapperClass: "dashboard-escalations"
            },
            {
                route: "dashboard-statistics-ppm-requiring-approval",
                params: angular.extend({}, getFilterParams(), { status: "active" }),
                data: false,
                enabled: false,
                type: "planned_requiring_approval",
                responseKey: "sitetasksrequiringapproval",
                dashboardWidget: "planned_jobs_requiring_approval",
                header: "SITES_WITH_PPM_REQUIRING_APPROVAL",
                headerIcon: "fa-calendar-check-o",
                wrapperClass: "dashboard-escalations"
            },
            {
                route: "dashboard-statistics-job-requisites-requiring-approval",
                data: false,
                enabled: false,
                dashboardWidget: "job_requisites_requiring_approval",
                type: "job_requisites_requiring_approval",
                header: "JOB_REQUISITES_REQUIRING_APPROVAL",
                headerIcon: "fa-file-text-o",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-open-integration-actions",
                data: false,
                enabled: false,
                dashboardWidget: "open_integration_actions",
                header: "Loading",
                headerIcon: "fa-flag",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-p2p-jobs",
                data: false,
                enabled: false,
                dashboardWidget: "p2p_jobs",
                moduleName: "p2p_enabled",
                type: "job_p2p",
                header: "P2P_JOBS",
                headerIcon: "fa-clipboard",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-job-values",
                data: false,
                enabled: false,
                dashboardWidget: "job_values",
                moduleName: "job_value_enabled",
                type: "job_values",
                header: "JOB_VALUES",
                headerIcon: "fa-clipboard",
                wrapperClass: "dashboard-surveys"
            },
            {
                route: "dashboard-statistics-contract-timelines",
                data: false,
                enabled: false,
                dashboardWidget: "contract_timelines",
                type: "contracts_misc",
                header: "CONTRACT_TIMELINES",
                headerIcon: "fa-file-text-o",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-contract-renewals",
                data: false,
                enabled: false,
                dashboardWidget: "contract_renewals",
                type: "contracts_renewals",
                header: "CONTRACT_RENEWALS",
                headerIcon: "fa-file-text-o",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-contract-approvals",
                data: false,
                enabled: false,
                dashboardWidget: "contract_approvals",
                type: "contracts_awaiting_approval",
                header: "CONTRACT_APPROVALS",
                headerIcon: "fa-file-text-o",
                wrapperClass: "dashboard-updates"
            },
            {
                route: "dashboard-statistics-asset-score-type-1",
                customData: [],
                data: false,
                header: "ASSET_SCORE",
                headerIcon: 'fa-cubes',
                enabled: false,
                dashboardWidget: "asset_score_type_1",
            },
            {
                route: "dashboard-statistics-asset-score-type-2",
                customData: null,
                data: false,
                header: "ASSET_SCORE",
                headerIcon: 'fa-cubes',
                enabled: false,
                dashboardWidget: "asset_score_type_2",
            },
            {
                route: "dashboard-statistics-asset-score-type-3",
                customData: null,
                data: false,
                header: "ASSET_SCORE",
                headerIcon: 'fa-cubes',
                enabled: false,
                dashboardWidget: "asset_score_type_3",
            },
            {
                route: "dashboard-statistics-performance-statistics",
                data: false,
                enabled: false,
                dashboardWidget: "performance_statistics",
                type: "performance_statistics",
                header: "PERFORMANCE_STATISTICS",
                headerIcon: "fa-line-chart",
                wrapperClass: ""
            }
        ];
    };

    function isIntegrationActionsEnabled() {
        var enabled = false;
        angular.forEach(openIntegrationActionsModules, function (element) {
            if (elbSettings.getSetting(element) && elbSettings.getSetting(element).value) {
                enabled = true;
            }
        });

        return enabled;
    }

    function getStatistics() {
        var mandatoryDashboardWidgets = elbSettings.getSetting('dashboard_widgets').value;
        // Filter out stats user has no permissions for
        self.dashboardStats = lodash.filter(self.dashboardStats, function(stat) {
            stat.loading = false;
            if(
                (userManager.user.getLink(stat.route) &&
                mandatoryDashboardWidgets.indexOf(stat.dashboardWidget) !== -1 || stat.dashboardWidget.indexOf('asset_score_type_') === 0)
            ) {
                if (stat.dashboardWidget.indexOf('asset_score_type_') === -1) {
                    stat.enabled = true;
                    stat.mandatory = true;
                } else if (stat.dashboardWidget.indexOf('asset_score_type_') === 0) {//This statement is specific to Asset score type dashboard widgets
                    var assetScoreTypeMandatory = mandatoryDashboardWidgets.filter(function(el) {
                            return el.indexOf(stat.dashboardWidget) === 0;
                        }
                    );

                    if (assetScoreTypeMandatory.length > 0) {
                        stat.enabled = true;
                        stat.mandatory = true;
                    } else if (assetScoreTypeMandatory.length > 0) {
                        stat.enabled = true;
                        stat.mandatory = true;
                    } else if (assetScoreTypeMandatory.length > 0) {
                        stat.enabled = true;
                        stat.mandatory = true;
                    }
                }
            }
            return userManager.user.getLink(stat.route);
        });

        // Only queue requests for enabled stats
        return lodash.map(self.dashboardStats, function(stat) {
            if (!stat.enabled && stat.dashboardWidget.indexOf('asset_score_type_') === -1) {
                return;
            }

            var params = getFilterParams();

            if (stat.hasOwnProperty('params')) {
                params = angular.extend({}, stat.params, params);
                var site = params.site;
                var region = params.region;
                var userId = params.userId;
                var siteAssociateType = params.siteAssociateType;

                params = stat.params;
                params.site = site;
                params.region = region;
                params.userId = userId;
                params.siteAssociateType = siteAssociateType;
            }

            return apiClient.get(userManager.user.getLink(stat.route), params).then(function(statistics) {
                if (stat.hasOwnProperty('responseKey')) {
                    stat.data = statistics[stat.responseKey];
                } else if (stat.route.indexOf('dashboard-statistics-asset-score-type-') > -1 && JSON.parse(statistics.userDashboardStatistics[0].data).length > 0) {
                    stat.customData = statistics.userDashboardStatistics[0].customData;
                    stat.data = statistics.userDashboardStatistics;
                } else if (stat.route.indexOf('dashboard-statistics-asset-score-type-') > -1 && JSON.parse(statistics.userDashboardStatistics[0].data).length === 0) {
                    stat.data = false;
                    stat.enabled = false;
                } else if (stat.route.indexOf('finance-statistics-') > -1 && JSON.parse(statistics.financeDashboardStatistics[0].data).length > 0) {
                    stat.type = statistics.financeDashboardStatistics[0].type;
                    stat.data = JSON.parse(statistics.financeDashboardStatistics[0].data);
                } else {
                    stat.data = statistics.userDashboardStatistics;
                }

                if (stat.route === 'dashboard-statistics-ppm-requiring-approval-sp') {
                    stat.canViewPlanner = statistics.canViewPlanner;
                }

                if (stat.route === 'dashboard-statistics-open-integration-actions' && !modulesService.checkIntegrationsEnable()) {
                    stat.enabled = false;
                }

                if (stat.data !== false) {
                    try {

                        if (!stat.hasOwnProperty('type')) {
                            stat.type = stat.data[0].type;
                        }

                        if (!stat.hasOwnProperty('responseKey') && lodash.isString(stat.data[0].data)) {
                            stat.data = JSON.parse(stat.data[0].data);
                        }
                        stat.loading = true;
                    } catch(e) {}
                    setStatProperties(stat);
                    return stat;
                }
            });
        });
    }

    function getFilterParams() {
        var params = {
            site: JSON.parse($window.localStorage.getItem('siteFilter')),
            region: JSON.parse($window.localStorage.getItem('regionFilter')),
            siteAssociateType: JSON.parse($window.localStorage.getItem('siteAssociateTypeFilter')),
            userId: JSON.parse($window.localStorage.getItem('userIdFilter'))
        };
        // reloadUserPreferences is for FE purpose only . Not to be sent to BE
        return angular.extend({}, $stateParams, params, { reloadUserPreferences: undefined });
    }

    function statToggled(stat) {
        stat.loading = false;
        const dashboardLayoutValue = JSON.stringify(
            lodash.map(
                self.dashboardStats,
                function(stat) {
                    return {
                        stat: stat.route,
                        enabled: stat.enabled
                    }
                }
            )
        );
        let preferencesRequest = apiClient.update(
            userManager.user.getLink('preferences'),
            {
                preferences: [
                    {
                        name: 'dashboardLayout',
                        value: dashboardLayoutValue
                    }
                ]
            }
        ).then(function() {
            userManager.setPreferenceOption('dashboardLayout', dashboardLayoutValue);
        });

        if (!stat.data || stat.route.indexOf('dashboard-statistics-asset-score-type-') > -1) {
            self.showStatistics = false;
            var params = getFilterParams();

            if (stat.hasOwnProperty('params')) {
                var site = params.site;

                params = stat.params;
                params.site = site;
            }

            let statisticRequest = apiClient.get(userManager.user.getLink(stat.route), params).then(function(statistics) {
                try {
                    if (stat.hasOwnProperty('responseKey')) {
                        stat.data = statistics[stat.responseKey];
                    } else if (stat.route.indexOf('dashboard-statistics-asset-score-type-') > -1 && JSON.parse(statistics.userDashboardStatistics[0].data).length > 0) {
                        stat.customData = statistics.userDashboardStatistics[0].customData;
                        stat.data = JSON.parse(statistics.userDashboardStatistics[0].data);
                        stat.type = statistics.userDashboardStatistics[0].type;
                    } else if (stat.route.indexOf('dashboard-statistics-asset-score-type-') > -1 && JSON.parse(statistics.userDashboardStatistics[0].data).length === 0) {
                        stat.data = false;
                        stat.customData = null;
                        stat.type = null;
                    } else if (stat.route.indexOf('finance-statistics-') > -1 && JSON.parse(statistics.financeDashboardStatistics[0].data).length > 0) {
                        stat.type = statistics.financeDashboardStatistics[0].type;
                        stat.data = JSON.parse(statistics.financeDashboardStatistics[0].data);
                    } else {
                        stat.type = statistics.userDashboardStatistics[0].type;
                        stat.data = JSON.parse(statistics.userDashboardStatistics[0].data);
                    }

                    if (stat.route === 'dashboard-statistics-ppm-requiring-approval-sp') {
                        stat.canViewPlanner = statistics.canViewPlanner;
                    }

                } catch (e) {}

                setStatProperties(stat);
                self.showStatistics = true;
            });
            $q.all([preferencesRequest, statisticRequest]).then(function() {
                stat.loading = true;
            });
        } else {
            stat.data = false;
        }
    }

    function applyUserPreferences(userPreferencesResponse) {
        const userPreferences = userPreferencesResponse.hasOwnProperty('userPreferences') ? userPreferencesResponse.userPreferences : userPreferencesResponse;
        var dashboardLayout = lodash.filter(userPreferences, function(preference) {
            return preference.name === 'dashboardLayout';
        });

        if (dashboardLayout.length === 0) {
            angular.forEach(self.dashboardStats, function(stat) {
                if(stat.route !== 'dashboard-statistics-ppm-requiring-approval-sp') {
                    stat.enabled = true;
                }

                if(stat.route === 'dashboard-statistics-open-flagged-priority-jobs') {
                    stat.enabled = false;
                }
            });

            return;
        }

        dashboardLayout = JSON.parse(dashboardLayout[0].value);
        angular.forEach(self.dashboardStats, function(stat) {
            var match = lodash.find(dashboardLayout, {stat: stat.route});

            if (match) {
                stat.enabled = match.enabled;
            } else {
                stat.enabled = true;
            }
        });
    }

    function setStatProperties(stat) {
        if (stat.type === 'action_open' && stat.data.length > 0) {
            var byPlannedDate = lodash.find(stat.data, {key: null});

            if (byPlannedDate) {
                byPlannedDate.key = $translate.instant('BY_PLANNED_DATE');
                byPlannedDate.color = elbSettings.getSetting('general_non_priority_colour').value;
            }
        }

        stat.columns = 1;
        if (stat.type === '') {
            stat.columns = 2;
        } else if (stat.type === 'performance_statistics') {
            stat.columns = 4;
        }
    }
});
