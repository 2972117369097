(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            CONTACT_US: 'Contact Us',
            PRIVACY_POLICY: 'Privacy Policy',
            SUMMARY: 'Summary',

            WORKFLOW: 'Workflow',
            LINKS_TO: 'Links To',
            APPROVALS_REQUIRED: 'Outstanding Approvals',
            WORKFLOW_ACTION_NOT_AVAILABLE:'Workflow action no longer available or has now been completed',

            ENTER_DETAILS_MANUALLY: 'Enter details manually',
            PHONE_NUMBER: 'Phone number',
            RELATIONSHIP_TO_THE_PROPERTY: 'Relationship to the Site',
            NOTIFY_ON_CREATION: 'Notify on creation',
            NOTIFY_ON_COMPLETION: 'Notify on completion',
            NOTIFY_ON_ACCEPTANCE: 'Notify on acceptance',

            REPORTER_NAME: 'Reporter Name',

            EDIT_ALARM: 'Edit Alarm',
            SET_ALARM: 'Set Alarm',
            ALARM_LOCKED: 'Locked',

            UNLIMITED: 'Unlimited',

            COLOUR: 'Colour',
            NONE_SET: 'None set',
            COUNT: 'Count',

            DESCRIPTION: 'Description',
            ID: 'ID',
            NO: 'No',
            NONE_SELECTED: 'None Selected',
            PLEASE_SELECT: 'Please select an option',
            NOTES: 'Notes',
            VALUE: 'Value',
            YES: 'Yes',
            NAME: 'Name',
            FILTER_BY_NAME: 'Filter by name',
            REFERENCE: 'Reference',
            EXTERNAL_REFERENCE: 'External Reference',
            INTERNAL_REFERENCE: 'Internal Reference',
            STATUS: 'Status',
            ADDRESS: 'Address',
            PHONE: 'Phone',
            TYPE: 'Type',
            LOCATION: 'Location',
            EMAIL_ADDRESS: 'Email',
            EMAIL_SIGNATURE: 'Email Signature',
            INFO: 'Info',
            COMPANY: 'Company',
            ALL: 'All',
            POSITION: 'Position',
            NEW_CUSTOMER_ACCESS: 'New Customer Access',
            FAILED_LOGIN_ATTEMPTS: 'Failed Login Attempts',
            ACCOUNT_LOCKED: 'Account Locked.',
            UNLOCK_USER: 'Unlock User',
            SEND_PASSWORD_RESET: 'Send Password Reset',
            PASSWORD_RESET_EMAIL_SENT: 'Password Reset Email Sent',
            SEND_USERNAME_REMINDER: 'Send Username Reminder',
            USERNAME_REMINDER_EMAIL_SENT: 'Username Reminder Email Sent',
            ACCOUNT_UNLOCKED: 'User Unlocked',
            IS_TEMPLATE: 'Is Template',
            EMAIL: 'Email',
            ADDRESS_LINE_1: 'Address Line 1',
            ADDRESS_LINE_2: 'Address Line 2',
            ADDRESS_LINE_3: 'Address Line 3',
            ADD_ADDRESS: 'Add Address',
            GO: 'Go',
            REPORTER: 'Reporter',
            NOTE: 'Note',
            MAIN: 'Main',
            EMAILS: 'Emails',

            CURRENT_VERSION: 'Current Version',
            NEW_VERSION: 'New Version',
            VERSION: 'Version',
            CREATE_NEW_VERSION: 'Create New Version',
            NEW_VERSION_CREATED: 'New Version Created',
            ACTIVATE_VERSION: 'Activate Version',
            DEACTIVATE_VERSION: 'Deactivate Version',
            COPY_SCHEDULE: 'Copy Schedule',
            ACTIVE_FROM: 'Active From',

            DASHBOARD: 'Dashboard',
            ADMIN: 'Admin',
            NOTIFICATIONS: 'Notifications',
            NO_NOTIFICATIONS: 'You have no notifications',
            BUDDY_SWITCHED_ON: 'Buddy Switched On',
            BUDDYING_FOR_ON: 'Buddying For On',

            ADD_SUCCESS: 'Added Successfully',
            UPDATE_SUCCESS: 'Updated Successfully',
            EDIT_SUCCESS: 'Edited Successfully',
            DELETE_SUCCESS: 'Deleted Successfully',
            REQUEST_ERROR: 'There was an error with the request. Please try again later.',

            VIEW: 'View',
            ADD: 'Add',
            CREATE: 'Create',
            EDIT: 'Edit',
            UPDATE: 'Update',
            UPDATES: 'Updates',
            UPDATED: 'Updated',
            ORIGINAL: 'Original',
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            CLOSE: 'Close',
            RESET: 'Reset',
            CONFIRM: 'Confirm',
            PREVIOUS: 'Previous',
            DECLINED: 'Declined',
            ACTIVATE: 'Activate',
            DEACTIVATE: 'Deactivate',

            PARENT: 'Parent',
            CHILDREN: 'Children',

            IS_ACTIVE: 'Status',
            STATUS_ACTIVE: 'Active',
            STATUS_INACTIVE: 'Inactive',
            STATUS_ALL: 'All',
            NEW: 'New',
            EDITED: 'Edited',
            NEW_EDITED: 'New / Edited',

            ARE_YOU_SURE_HEADER: 'Are you sure?',
            ARE_YOU_SURE: 'Are you sure you want to perform this action?',
            NAVIGATION_CONFIRMATION: 'Are you sure you want to leave this page? Any changes you\'ve made will be lost.',

            REASON_FOR_STATUS_CHANGE: 'Please write a reason for status change',

            BARCODE: 'Barcode',
            FILES: 'Files',
            FILES_WITH_COUNT: 'Files ({{ ::count || 0 | number:0 }})',

            ALL_SERVICE_PROVIDERS: 'All Service Providers',
            SELECTED_SERVICE_PROVIDERS: 'Selected Service Providers',
            SELECT_SERVICE_PROVIDERS: 'Select Service Providers',

            ALL_PARTNERSHIPS: 'All Partnerships',
            SELECTED_PARTNERSHIPS: 'Selected Partnerships',

            TITLE: 'Title',

            FOOTER_COPYRIGHT: 'Copyright &copy; Elogbooks Facilities Management Ltd {{ ::currentYear }} - Reg Company No. 07259147',

            ZERO_OPTIONS_CHECKED_TITLE: 'No Items Selected',
            ZERO_OPTIONS_CHECKED_MESSAGE: 'You must select at least one item before performing this action',


            SERVICEPROVIDER: 'Service Provider',
            STATUTORYTYPE: 'Statutory Type',
            STATUTORY_TYPE: 'Statutory Type',

            START_TYPING_TO_SEARCH: 'Start typing to search',
            START_TYPING_TO_FILTER: 'Start typing to filter',

            START_TYPING_TO_SEARCH_SITES: 'All Sites - Start typing to search',
            START_TYPING_TO_SEARCH_REGIONS: 'All Regions',
            START_TYPING_TO_SEARCH_ASSOCIATE_TYPES: 'All Associate Types',
            START_TYPING_TO_SEARCH_USERS: 'All Users',

            ACTION_SUCCESS: 'Action Success',
            ACTIVE: 'Active',
            INACTIVE: 'Inactive',
            DRAFT: 'Draft',

            LOCAL_SITE_TIME: 'Site Local Time',

            WITH_ID: '<strong>ID</strong> {{ ::id }}',
            WITH_SITE: '<strong>Site</strong> {{ ::site }}',
            WITH_NAME: '<strong>Name</strong> {{ ::name }}',
            WITH_SUMMARY: '<strong>Summary</strong> {{ ::summary }}',
            WITH_REFERENCE: '<strong>Reference</strong> {{ ::reference }}',
            WITH_CHILDREN: '<strong>Children</strong> {{ ::children }}',
            WITH_ACTIVE: '<strong>Active</strong> {{ ::(active == "true" || active == true ? "YES" : "NO") | translate }}',
            WITH_ALLOW_SERVICEPROVIDER_ACCESS: '<strong>Allow service provider access</strong> {{ ::(allowServiceproviderAccess == true ? "YES" : "NO") | translate }}',
            CONTACT: 'Contact',
            CONTACTS: 'Contacts',


            BACK: 'Back',

            NO_RESULTS_FOUND: 'No results found',
            PAGINATION_SHOWING: 'Showing {{::from}} to {{::to}} of {{::total}}',
            DETAILS: 'Details',

            SUBMIT: 'Submit',

            VISIBILITY_PUBLIC: 'Public',
            VISIBILITY_HELPDESK: 'Helpdesk',

            INFORMATION: 'Information',
            SEND_EMAIL: 'Send Email',
            GENERAL: 'General',

            ATTENDANCE_DUE: 'Attendance Due',
            COMPLETION_DUE: 'Completion Due',
            NEW_COMPLETION_DUE: 'New Completion Due',
            CREATED_AT: 'Created At',
            APPROVED_AT: 'Approved At',
            CREATED_BETWEEN: 'Created Between',

            ACTION_CANCEL: 'Cancel',
            ACTION_COMPLETE: 'Complete',
            ACTION_RAISE_JOB: 'Raise Job',
            ACTION_RAISE_QUOTE: 'Raise Quote',
            ADD_BULK_PATROL_OCCURRENCES: 'Add Patrol Occurrences',
            ADD_CONTRACT_TYPE: 'Add Contact Type',
            ADD_DEPARTMENT: 'Add Department',
            ADD_NEW_CONTRACT_TYPE: 'Add New Contract Type',
            ADD_NEW_DEPARTMENT: 'Add New Department',
            ADD_NEW_READING: 'Add New Reading',
            ADD_NEW_SUBMETER: 'Add New Submeter',
            ADD_NEW_TRADE_TYPE: 'Add New Trade Type',
            ADD_TRADE_TYPE: 'Add Trade Type',
            ALL_USERS: 'All Users',
            ASSIGNEE: 'Assignee',
            ATTACH_A_FILE: 'Attach a file',
            ATTACHMENT: 'Attachment',
            ATTACHMENTS: 'Attachments',
            ATTENDANCE_DATE: 'Attendance Date',
            ATTENDED: 'Attended',
            BY_PLANNED_DATE: 'By Planned Date',
            CANCELLED: 'Cancelled',
            COMPLETED: 'Completed',
            COMPLETION_BY_DATE: 'Completion By',
            COMPLETION_DUE_BETWEEN: 'Completion Due Between',
            CREATED_BY: 'Created By',
            COMPOSE_CONVERSATION: 'Compose Conversation',
            CONDITION: 'Condition',
            CONTRACT_APPROVAL: 'Contract Approval',
            CONTRACT_TERMINATED: 'Contract Terminated',
            CONTRACT_TYPES: 'Contract Types',
            CONVERSATIONS: 'Conversations',
            CREATE_AS_GLOBAL_TEMPLATE: 'Create as Global Template',
            CREATED: 'Created',
            DATE_CREATED: 'Date Created',
            DATE_READ: 'Date Read',
            DELETE_SURVEY: 'Delete Survey',
            DEPARTMENTS: 'Departments',
            DONE: 'Done',
            EDIT_CONTRACT: 'Edit Contract',
            EDIT_CONTRACT_TYPE: 'Edit Contract Type',
            EDIT_DEPARTMENT: 'Edit Department',
            EDIT_TRADE_TYPE: 'Edit Trade Type',
            EDITABLE: 'Editable',
            EXPORT: 'Export',
            EXPORT_NOTES: 'Export Notes',
            EXPORT_IN_PROCESS: 'Export in queue',
            EXPORT_IN_PROCESS_WITH_LIMIT: 'Export in queue, but data will be limited to {{ limit }} items',
            EXPORT_KPI_TREND: 'Export KPI Trend',
            EXPORT_KPI_DATA: 'Export KPI Data',
            GROUP: 'Group',
            HARD_SERVICE: 'Hard Service',
            HELPDESK_NOTES: 'Helpdesk Notes',
            HIDE: 'Hide',
            JOB_HISTORY_NOTE: 'Job History Note',
            JOB_MORE_MESSAGES: 'More Messages',
            JOB_NEXT_PLANNED_VISIT: 'Next Planned Visit',
            JOB_STATUS_: 'Status',
            LAST_MESSAGE: 'Last Message',
            LAST_SUBMITTED_BETWEEN: 'Last Submitted Between',
            LATEST_REACTIVE_JOBS: 'Latest Reactive Jobs',
            LEGEND_: 'Legend',
            LOCATION_BASED_SECTION: 'Location Based Section',
            MONTHS: 'Months',
            'N/A': 'N/A',
            NEW_JOB: 'New Job',
            NEXT: 'Next',
            NO_ANSWER_OPTIONS: 'No Answer Options',
            NO_NOTE_PROVIDED: 'No Note Provided',
            NOTE_REQUIRED: 'Note Required',
            NO_REACTIVE_JOBS: 'No Reactive Jobs',
            NO_SERVICE_PROVIDER_ENTERED: 'No Service Provider Entered',
            NO_SITE_ASSOCIATES_ASSIGNED: 'No Site Associates Entered',

            NO_DECIMAL_ALLOWED : 'Decimal Numbers Not Allowed',
            NON_CHARGEABLE: 'Non Chargeable',
            NONE: 'None',
            ON_SITE_DATE: 'On Site Date',
            OOH_NOTES: 'OOOH Notes',
            OOH_PHONE: 'OOH Phone',
            OPEN: 'Open',
            OR: 'or',
            ORDER: 'Order',
            OVERDUE_NO: 'No',
            OVERDUE_YES: 'Yes',
            OWNER: 'Owner',
            PREV: 'Previous',
            RAISED_BY: 'Raised By',
            READING: 'Reading',
            REQUEST: 'Request',
            RISK: 'Risk',
            SATISFACTION_SURVEY: 'Satisfaction Survey',
            SAVE: 'Save',
            SCORE_RANGE: 'Score Range',
            SEARCH: 'Search',
            SEARCH_SITE: 'Search Site',
            SELECT: 'Select',

            SELECT_A_USER: 'Select a User',
            SELECT_CONDITION: 'Select Condition',
            SELECT_DEPARTMENT: 'Select Department',
            SELECT_RISK: 'Select Risk',
            SELECT_SURVEY: 'Select Survey',
            SELECTED_USERS: 'Selected Users',
            SEND: 'Send',
            SERVICE: 'Service',
            SERVICE_PROVIDED: 'Service Provided',
            SHOW: 'Show',
            SKIP_THIS_TIME: 'Skip this time',
            SOFT_SERVICE: 'Soft Service',
            STATUS_DETAILS: 'Status Details',
            SUBMITTED: 'Submitted',
            SURVEY_RANGE: 'Range',
            SURVEY_WEIGHT: 'Weight',
            TARGET: 'Target',
            TELEPHONE: 'Telephone',
            TRADE_TYPES: 'Trade Types',
            UNABLE_TO_CREATE_TENANT: 'Unable to create tenant',
            UNABLE_TO_GET_LOCATION: 'Unable to get location',
            UNABLE_TO_GET_TENANT: 'Unable to get tenant',
            UNABLE_TO_UPDATE_TENANT: 'Unable to update tenant',
            UNSUBSCRIBE: 'Unsubscribe',
            VIEW_NOTIFICATIONS_UNREAD_NEWER: 'Newer',
            VIEW_NOTIFICATIONS_UNREAD_OLDER: 'Older',
            MARK_ALL_AS_READ: 'Mark all as read',
            WEBSITE: 'Website',
            WEIGHT: 'Weight',
            WEIGHTED_SCORE: 'Weighted Score',
            WRITE_A_MESSAGE: 'Write a Message',
            FILE_ATTACHMENT: 'File Attachment',
            COPY_ME: 'Send a Copy To Me',
            SUBJECT: 'Subject',
            TO: 'To',
            EMAIL_S_SENT: 'Email(s) Sent',
            SHOW_MORE: 'Show More',
            SHOW_LESS: 'Show Less',
            EDIT_RECIPIENTS: 'Edit Recipients',
            RECIPIENTS: 'Recipients',
            VIEW_ALL: 'View All',
            WILL_CHASE_AT: 'Will Chase At',
            EXTENSION_DATE: 'Extension Date',
            I_AM_THE_APROVER: 'Approver',
            I_AM_APPROVER: 'I am Approver',
            RESIDENTIAL: 'Residential',
            COMMERCIAL: 'Commercial',
            COMPLIANCE_SYSTEM_TYPE: 'Q Compliance System Type',
            MESSAGE_CREATED: 'Message Created',
            ACCEPT: 'Accept',
            REJECT: 'Reject',
            DUE_DATE: 'Due Date',
            FEET: 'Feet',
            METRES: 'Metres',
            IMAGE_FILE: 'Image',
            NOTIFICATION_NOT_FOUND: 'Notification not found',
            NOTIFICATION_TYPE_NOT_FOUND: 'Notification Type not found',
            OTHER: 'Other',
            SKIPPED_NOTIFICATION: 'Skipped Notification',
            REMINDER_AT: 'Reminder At',
            REQUESTED: 'Requested',
            INCLUDE_MY_SIGNATURE: 'Include My Signature',
            LOAD_MORE: 'Load More',
            AT_LEAST_ONE_ITEM_REQUIRED: 'At least one item is required.',
            NO_MORE_RESULTS: 'No more results to load.',
            MAX_NUMBER_SELECTED: 'Selected maximum number of items.',
            EXPIRED: 'Expired',
            VALID: 'Valid',
            REVOKE: 'Revoke',
            REVOKED: 'Revoked',
            INDEFINITE: 'Indefinite',
            FOR_REVIEW: 'For Review',
            PAPERWORK: 'Paperwork',
            CREATED_AT_BETWEEN: 'Created At Between',
            DATE_COMPLETE_AT: 'Completed At',
            COPIED_TO_CLIPBOARD: 'Copied to Clipboard',
            BROWSER_UNSUPPORTED: 'Browser Unsupported',
            IMAGES_UPDATED: 'Images have been updated',
            IMAGE_DELETE: 'Image has been deleted',
            IMAGE_UPLOAD: 'Image has been uploaded',
            IMAGE_UPDATE: 'Image has been updated',
            NO_IMAGE_SELECTED: 'No image selected',
            SET_MAIN_IMAGE: 'Set as main image',
            INCORRECT_FILE_TYPE: 'Incorrect file type',
            INCORRECT_FILE_ASPECT_RATIO: 'Incorrect file dimension',
            INVALID_PERMISSIONS: 'Invalid permissions',
            NO_RESOURCE_FOUND: 'No Resource Found',
            FILTERED: 'Filtered',
            SELECTED: 'Selected',
            APPROVER_IS_NOT_SET: 'Approval is required but no approver has been set',
            SELECT_AT_LEAST_ONE_SITE: 'At least one site is required',
            ITEM_FIELD_REQUIRED: 'Item field is required',
            SELECTALLSITES: 'Select all sites',
            TOTALS: 'Totals',
            PLANNED_JOBS: 'Planned Jobs',
            REACTIVE_JOBS: 'Reactive Jobs',
            JOB_DESCRIPTION: 'Job Description',
            REPORT_ON: 'Report On',
            TABLE_ACTIONS: 'Table Actions',
            EXPAND_ALL: 'Expand All',
            COLLAPSE_ALL: 'Collapse All',
            EXPIRED_AT: 'Expired At',
            REJECTED_AT: 'Rejected At',
            NO_RESULTS: 'No Results',
            DECLINED_AT: 'Declined At',
            ACCEPTED_AT: 'Accepted At',
            FIELD_WITH_MAXIMUM_LENGTH: '{{ fieldName }} field maximum length is {{ limit }} characters',
            NOTES_FIELD_WITH_MAXIMUM_LENGTH_20: 'Notes field maximum length is 20 characters',
            START_DATE: 'Start Date',
            END_DATE: 'End Date',
            UPLOAD_SITE_IMAGE: 'Upload a site image',
            ACCESS_DENIED: 'Access Denied',
            CIRCULAR_REFERENCE: 'Circular reference',
            RELATION_LINK_PLANNED_TO_RESTRICTION: 'Planned job can\'t be linked to other jobs',
            RELATION_LINK_PLANNED_FROM_QUOTE_RESTRICTION: 'Planned job can\'t be linked from quote',
            RELATION_LINK_PLANNED_TO_QUOTE_RESTRICTION: 'Planned job can\'t be linked to quote',
            RELATION_LINK_PLANNED_TO_ACTION_RESTRICTION: 'Planned job can\'t be linked to action',
            DUPLICATED_RELATION_LINK: 'Job link already exists',
            NEW_TAG: ' (New)',
            DELETED: 'Deleted',
            NOT_FOUND: 'Not found',
            EXPIRES_AT: 'Expiry Date',
            SERVICETYPE: 'Service Type',
            LOCATION_REQUIRED: 'Location Required',
            PHOTO_IS_REQUIRED: 'Photo is Required',
            RATIO: 'Ratio',
            TRAINING_ACADEMY: 'Training Academy',
            RELEASE_NOTES: 'Release Notes',
            ORDER_MUST_BE_A_NUMBER: 'Order must be a number',
            DUPLICATED_ORDER: 'Duplicated order',
            INCORRECT_FORMAT: 'Incorrect format',
            NO_ASSOCIATE_TYPE_FOR_SITE: 'No Such Associate Type For This Site',
            MAX_CHARACTER_COUNT: 'Max 40 characters',
            PIXELS: 'Pixels',
            'N_A': 'N/A',
            EITHER: 'Either',
            MARKUP: 'Management Charge',
            MARKED_UP_VALUE: 'Marked Up Value',
            SEND_BETWEEN_DATES: 'Send Between Dates',
            EFS_VALUE: 'Subcontractor Cost',
            EMAIL_DATE: 'Date',
            EMAIL_SUBJECT: 'Subject',
            EMAIL_SENDER: 'Sender',
            EMAIL_ATTACHMENT: 'Attachment',
            EMAIL_SENT_START: 'Sent Start',
            EMAIL_SENT_END: 'Sent End',
            EMAIL_RECIPIENT_NAME: 'Recipient Name',
            EMAIL_DATE_TIME: 'Date and Time sent',
            REPLY_TO: 'Reply-to Address',
            INVALID_REPLY_TO_ADDRESS: 'Invalid Reply-to Address',

            BACK_TO_LIST: 'Back To List',
            COMMENT_INVALID_LENGTH: 'Comment cannot be longer than 255 characters',
            COMMENT_1000_OR_LESS: 'Comment cannot be longer than 1000 characters',
            MANDATORY: 'Mandatory',
            PUBLIC: 'Public',
            PRIVATE: 'Private',
            COMMENT: 'Comment',

            ATTENDANCE: 'Attendance',
            COMPLETION: 'Completion',
            JOBS_DUE_BY_DAY: 'Day(s)',
            JOBS_DUE_BY_WEEK: 'Week(s)',
            JOBS_DUE_BY_MONTH: 'Month(s)',

            JOBS_DUE_BY_SELECT: 'Select Type',
            JOBS_DUE_BY_FREQUENCY_SELECT: 'Select Frequency',

            DOWNLOAD_PDF: 'Download as PDF',
            STAT: 'Stat Type',
            SYSTEM_NOTIFICATIONS_OPEN_URL: 'Click to read more',

            REMEDIALS: 'Remedials',
            REMEDIAL: 'Remedial',
            SITE_STATUS: 'Site Status',
            REQUIRES_REMEDIAL: 'Requires Remedial',
            REMEDIAL_REQUIRED: 'The job requires a remedial to be raised before completing it',

            SUCCESS: 'Success',
            QUEUED: 'Queued',

            SELECT_ALL: 'Select all',

            DEFAULT: 'Default',
            IS_DEFAULT: 'Is Default',

            AMOUNT: 'Amount',

            COST_TYPE: 'Cost Type',
            SUBCONTRACTOR: 'Subcontractor',
            MATERIAL: 'Material',
            EQUIPMENT: 'Equipment',

            SUBCONTRACTORMARKUP: 'Subcontractor Markup',
            EQUIPMENTMARKUP: 'Equipment Markup',
            ITEMS: 'Items',

            PERCENTAGE_RATE:'Percentage Rate',
            SCHEDULED: 'Scheduled',

            MIN_TWO_SELECTED: 'Minimum 2 columns',

            PERIOD: 'Period',
            PENDING_APPROVAL: 'Pending Approval',
            APPROVED: 'Approved',
            LIST_VIEW: 'List View',
            SUBMIT_FOR_APPROVAL: 'Submit for Approval',

            MORNING: 'Morning',
            AFTERNOON: 'Afternoon',

            STAFF: 'Staff',
            EVERYONE: 'Everyone',

            ADD_ITEM: 'Add item',
            REMOVE_ITEM: 'Remove item',
            ADD_ALL_ITEMS: 'Add all items',
            REMOVE_ALL_ITEMS: 'Remove all items',

            SUPPLIER_REFERENCE: 'Supplier Reference',
            YEARLY_ENTITLEMENT: 'Yearly Entitlement',

            EXPORT_IS_ALREADY_QUEUED: 'Export Is Already Queued',

            CREATED_AT_START: 'Created At Start',
            CREATED_AT_END: 'Created At End',
            ADD_EDIT: 'Add-Edit',

            REPORTER_NAME_INVALID_LENGTH: 'Reporter name can not be longer than 255 characters',
            SITE_CONTACT_INVALID_LENGTH: 'Site contact name can not be longer than 255 characters',
            KEYHOLDER_INVALID_LENGTH: 'Keyholder can not be longer than 255 characters',

            CHANGES_MADE_BY: '<b> {{ ::name }} </b> updated Site Note - <b> {{ ::item }} </b>',
            SITE_UPDATED_ACTION: '<b> {{ ::name }} </b> updated the Site',
            SITE_CREATED_ACTION: '<b> {{ ::name }} </b> created the Site',
            EDIT_CONTACT_INFORMATION: 'Edit Contact Information',
            IMAGE_FILES_ONLY: 'Image files only',
            BLANK_OPTION: '(Blank)',

            CONTINUE: 'Continue',

            CUSTOM: 'Custom',
            LAST_ONE_MONTH: 'Last 1 month',
            LAST_THREE_MONTHS: 'Last 3 months',
            LAST_TWELVE_MONTHS: 'Last 12 months',
            RATING_STATUS: 'Rating Status',
            HAS_RATINGS: 'Has Ratings',
            NO_RATINGS: 'No Ratings',

            OPTIMA: 'Optima',

            PREFERENCES: 'Preferences',
            LOW: 'Low',
            HIGH: 'High',
            RETURN: 'Return',
            ADD_NOTE: 'Add Note',
            NO_ACCESS: "You don't have access to this page!"
        });
    }
})();
