(function () {
    // 'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobApprovalAcceptController', Controller('ACCEPT_APPROVAL', 'accept-approval', false, false))
        .controller('JobApprovalRejectController', Controller('REJECT_APPROVAL', 'reject-approval', true, false))
        .controller('JobApprovalEscalateController', Controller('ESCALATE_APPROVAL', 'escalate-approval', true, false))

        .controller('JobAssignmentReassignController', Controller('REASSIGN_ASSIGNMENT', 'reassign-assignment', false, false))
        .controller('JobAssignmentAcceptController', Controller('ACCEPT_ASSIGNMENT', 'accept-assignment', false, false))
        .controller('JobAssignmentRejectController', Controller('REJECT_ASSIGNMENT', 'reject-assignment', true, false))
        .controller('JobAssignmentInsistController', Controller('INSIST_ASSIGNMENT', 'insist-assignment', true, false))

        .controller('JobProposalRequestController', Controller('REQUEST_PROPOSAL', 'request-proposal', true))
        .controller('JobProposalAcceptController', Controller('ACCEPT_PROPOSAL', 'accept-proposal', false))
        .controller('JobProposalRejectController', Controller('REJECT_PROPOSAL', 'reject-proposal', true))
        .controller('JobProposalCancelController', Controller('CANCEL_PROPOSAL', 'cancel-proposal', true, false))

        .controller('JobExtensionRequestController', Controller('REQUEST_EXTENSION', 'request-extension', true)) /**/
        .controller('JobExtensionAcceptController', Controller('ACCEPT_EXTENSION', 'accept-extension', false, false))
        .controller('JobExtensionRejectController', Controller('REJECT_EXTENSION', 'reject-extension', true, false))
        .controller('JobExtensionCancelController', Controller('CANCEL_EXTENSION', 'cancel-extension', true, false))

        .controller('JobOperationCommencedController', Controller('COMMENCE_OPERATION', 'commence')) /**/
        .controller('JobOperationSkipController', Controller('SKIP_OPERATION', 'skip', true, false))

        .controller('JobOperationReopenController', Controller('REOPEN_OPERATION', 'reopen', true, false))
        .controller('JobOperationEscalateController', Controller('ESCALATE_OPERATION', 'escalate', true, false))
        .controller('JobOperationCancelController', Controller('CANCEL_OPERATION', 'cancel', true, false))
        .controller('JobRequisiteApproveController', Controller('REQUISITE_APPROVE', 'requisite-approve', false, false))
        .controller('JobRequisiteRejectController', Controller('REQUISITE_REJECT', 'requisite-reject', true, false));

    function Controller (translation, action, noteRequired, partial) {
        return function (apiClient, $state, messenger, user, jobResponse, requestDataFactory, formData, scopeData, $scope, confirmationModal, lodash, proposalEventResponse, $translate, integrationDocTypesResponse, modulesService) {
            var vm = this;
                vm.job = jobResponse;
                vm.user = user;
                vm.action = action;
                vm.translation = 'JOB_WORKFLOW_' + translation;
                vm.noteRequired = noteRequired;
                vm.partial = (typeof partial === 'undefined' ? vm.action : partial);
                vm.submit = submitAction;
                vm.changePriority = changePriority;
                vm.data = requestDataFactory.createRequest(formData);
                vm.data.files = {};
                vm.data.files.links = [];
                vm.proposalEvent = lodash.has(proposalEventResponse, 'events.0') ? proposalEventResponse.events[0] : null;
                vm.integrationDocSystem = integrationDocTypesResponse && integrationDocTypesResponse.integration ? integrationDocTypesResponse.integration : null;
                vm.integrationDocCats = integrationDocTypesResponse && integrationDocTypesResponse.docTypes ? integrationDocTypesResponse.docTypes : null;

                angular.extend(vm, scopeData);

            if (vm.selectedPriorityModel) {
                vm.selectedPriorityModel.onSelect = vm.selectedPriorityModel.onRemove = changePriority;

                if (vm.selectedPriorityModel.selected) {
                    vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                    vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');
                }
            }

            function changePriority () {
                if (vm.selectedPriorityModel.selected.object) {
                    vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                    vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                    vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                    if (vm.selectedPriorityModel.selected.object.alertMessageEnabled) {
                        confirmationModal.open({
                            titleMessage: 'PRIORITY_MESSAGE',
                            bodyMessage: vm.selectedPriorityModel.selected.object.alertMessage,
                            primaryText: 'OK',
                            primaryClass: 'btn-primary',
                            secondaryText: ''
                        });
                    }

                    if (vm.data.isByPlannedDate || vm.data.isOnNextPlannedVisit) {
                        delete vm.data._links.priority;
                    }
                } else {
                    delete vm.data._links.priority;
                    vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
                }
            }

            function submitAction () {
                var reloadState = $state.$current.parent.parent.parent.self.name;

                if (vm.data._links && vm.data._links.priority) {
                    vm.data.attendanceDueAt = vm.data.completionDueAt = null;
                }

                return apiClient.create(vm.job.getLink(action), vm.data).then(function (response) {
                    if (response) {
                        $state.go('^.^', {}, { reload: reloadState }).then(function () {
                            messenger.success(translation + '_SUCCESS');
                            if(action === 'commence' && vm.job.hasVersionedServiceRoutine) {
                                confirmationModal.open({
                                    titleMessage: 'SFG20',
                                    bodyMessage: 'SFG_WARNING',
                                    primaryText: 'OK',
                                    primaryClass: 'btn-primary',
                                    secondaryText: ''
                                });
                            }
                        });
                    }
                });
            }
        };
    }
})();
