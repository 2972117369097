(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobP2PController', JobP2PController);

    JobP2PController.$inject = [
        '$state',
        '$scope',
        'messenger',
        'jobResponse',
        'siteResponse',
        'requestDataFactory',
        'serviceProviderResponse',
        'apiClient',
        'p2pService',
        'jobGroupRequired',
        'jobGroupCollectionResponse',
        'serviceProviderService',
        'user',
        'userManager',
        'accountingElementsResponse',
        'p2pResponse',
        'elbSettings',
        'modulesService'
    ];

    function JobP2PController (
        $state,
        $scope,
        messenger,
        jobResponse,
        siteResponse,
        requestDataFactory,
        serviceProviderResponse,
        apiClient,
        p2pService,
        jobGroupRequired,
        jobGroupCollectionResponse,
        serviceProviderService,
        user,
        userManager,
        accountingElementsResponse,
        p2pResponse,
        elbSettings,
        modulesService
    ) {
        var vm = this;

        vm.isProactisIntegration = modulesService.isEnabled('p2p');
        vm.isHexagonIntegration = modulesService.isEnabled('hexagon_p2p');

        vm.serviceProvider = false;
        vm.jobApprover = false;
        vm.p2pPOApprover = userManager.hasPermission('site_permission_submit_p2p_job_po');
        vm.userResponse = user;
        vm.serviceProviderResponse = serviceProviderResponse;
        vm.job = jobResponse;
        vm.p2pCommentRequired = elbSettings.getSetting('general_p2p_new_format_client').value;
        vm.hexagonModel = {};

        if (!userManager.hasPermission('site_permission_helpdesk')) {
            vm.isOperative = !!jobResponse.getLink('p2p-value-job');
            vm.serviceProvider = !userManager.hasPermission('site_permission_p2p') && vm.isOperative;
        }

        if (jobResponse.getLink('current-approver') === user.getLink('self')
            || jobResponse.getLink('approved-by') === user.getLink('self')
            || vm.p2pPOApprover) {
            vm.jobApprover = true;
        }

        if (p2pResponse) {
            vm.isP2PSite = p2pResponse.isP2PSite;
            vm.isValidP2PUser = p2pResponse.isValidP2PUser;
            vm.p2pResponse = p2pResponse;
        }

        vm.hexagonLoading = false;
        if (vm.isHexagonIntegration) {
            vm.isP2PSite = true;
            vm.isValidP2PUser = true;
            vm.p2pResponse = null;
            vm.hexagonUrl = jobResponse.getLink('p2p-nominal-analysis');
            vm.hexagonPropertyReference = siteResponse.reference;
            vm.hexagonLoading = !vm.isOperative;
            $scope.$on('hexagonNominalCodes', function(event, args) {
                vm.hexagonLoading = args.loading;
            });

            vm.hexagonLinks = {
                locationsLink: siteResponse.getLink('locations'),
                tenantsLink: siteResponse.getLink('tenants')
            };
        }

        vm.submit = submitAction;

        vm.toggleZeroValue = toggleZeroValue;
        vm.toggleInputChange = toggleInputChange;
        vm.p2pSearchSupplier = p2pSearchSupplier;
        vm.addNominalLine = addNominalLine;
        vm.removeNominalLine = removeNominalLine;
        vm.getIfLengthRequired = getIfLengthRequired;

        vm.p2pValue = parseFloat(jobResponse.getLinkAttribute('p2p', 'value'));
        vm.p2pValueCopy = angular.copy(vm.p2pValue);
        vm.maxCharacterCount = 40;
        vm.ratiosInvalid = false;

        vm.serviceProviderNotSet = !serviceProviderResponse.p2pReference;

        vm.selectedP2PSupplierModel = {
            response: null,
            link: null,
            required: true,
            responseKeyPath: 'suppliers',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'Title',
            refreshOnLinkChange: false,
            onSelect: changeSupplier,
            onRemove: changeSupplier
        };

        vm.data = {
            _links: {},
            CIS: 'No',
            description: '',
            comment: '',
            nominalLines: [], // used to store data for building AccountingElements and tax code
            nominalLineData: [] // clean data for submission to API
        };

        vm.data.nominalLines = [];
        vm.data.nominalLineData = [];

        if (accountingElementsResponse) {
            createNominalLine();
        }

        const checkInvalidRatios = (data) => {
            const ratios = data.reduce(function (total, line) {
                return total + parseFloat(line.ratio.toFixed(2));
            }, 0);

            return ratios !== 100;
        }

        function toggleInputChange(event) {
            if (event === 0) {
                vm.toggleP2pValueModel = true;
            }
        }

        function toggleZeroValue() {
            if (vm.p2pValue !== 0) {
                vm.p2pValueCopy = vm.p2pValue;
                vm.p2pValue = 0;
            } else {
                vm.toggleP2pValueModel = false;
                vm.p2pValue = vm.p2pValueCopy;
            }
        }

        function addNominalLine() {
            createNominalLine();
        }

        function removeNominalLine(index) {
            vm.data.nominalLines.splice(index, 1);
            vm.data.nominalLineData.splice(index, 1);
        }

        function p2pSearchSupplier() {
            p2pService.updateSelectedSupplierModel(vm.selectedP2PSupplierModel, vm.p2pSupplierSearchModel, jobResponse);
        }

        function changeSupplier(supplierModel) {
            vm.data.p2pSupplier = supplierModel.selected.object.Code;
        }

        function submitAction() {
            var submitNominalLines = vm.jobApprover && vm.isValidP2PUser && vm.isP2PSite;
            if (!submitNominalLines || vm.toggleP2pValueModel === true) {
                // not jobApprover means no nominal lines OR value N/A was ticked - just submit value
                var url = '';
                var params = {};

                // An approver could not have p2p-value-job link so we use p2p-ponumber-instead when adding NA value
                if (jobResponse.getLink('p2p-value-job')) {
                    url = jobResponse.getLink('p2p-value-job');
                    params = { p2pValue: vm.p2pValue };
                } else {
                    url = jobResponse.getLink('p2p-ponumber-job');
                    params = { value: 0 };
                }

                apiClient.create(url, params).then(function (response) {
                    if (response) {
                        $state.go('dashboard.user.jobs.list', { jobsType: 'reactive', jobsStatus: 'completed', p2p: 1 }, { reload: true }).then(function () {
                            messenger.success('JOB_VALUE_UPDATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            } else {
                if (vm.isHexagonIntegration) {
                    vm.ratiosInvalid = checkInvalidRatios(vm.hexagonModel);
                    if (vm.ratiosInvalid) {
                        messenger.error('P2P_ERROR_RATIOS_INVALID');
                        return;
                    }

                    angular.forEach(vm.hexagonModel, (nominalLine) => {
                        if (nominalLine.unitReference) {
                            delete nominalLine.unitReference;
                        }
                    });

                    delete vm.hexagonModel.ratioError;
                    apiClient.create(jobResponse.getLink('p2p-hexagons-value-code'), {value: vm.p2pValue, nominalLines: vm.hexagonModel}).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.jobs.list', {
                                jobsType: 'reactive',
                                jobsStatus: 'completed',
                                p2p: 1
                            }, {reload: true}).then(function () {
                                messenger.success('JOB_PO_UPDATED');
                            });
                        }
                    });
                } else {
                    vm.ratiosInvalid = checkInvalidRatios(vm.data.nominalLineData);
                    if (vm.ratiosInvalid) {
                        messenger.error('P2P_ERROR_RATIOS_INVALID');
                        return;
                    }

                    var data = angular.copy(vm.data);
                    delete data.nominalLines; // vm.data.nominalLines is used for building accounting element forms and is unneeded, remove it before sending to API

                    data.value = vm.p2pValue;

                    apiClient.create(jobResponse.getLink('p2p-ponumber-job'), data).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.jobs.list', {
                                jobsType: 'reactive',
                                jobsStatus: 'completed',
                                p2p: 1
                            }, {reload: true}).then(function () {
                                messenger.success('JOB_PO_UPDATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            }
        }

        function createNominalLine() {
            // store model/response data for accounting elements and tax code
            var nominalLine = {
                accountingElements: angular.copy(accountingElementsResponse.accountingElements),
                taxCode: {
                    response: vm.p2pResponse,
                    link: null,
                    required: true,
                    responseKeyPath: 'taxCodes',
                    itemHrefPath: '_links.self.href',
                    itemValuePath: 'Description',
                    onSelect: onSelectTaxCode,
                    onRemove: onRemoveTaxCode,
                }
            };

            // store clean data for submission to API
            vm.data.nominalLineData.push({
                accountingElements: accountingElementsResponse.accountingElements.map(function (element) {
                    return {
                        type: element.type,
                        value: element.value
                    };
                }),
                ratio: null,
                taxCode: null
            });

            vm.data.nominalLines.push(nominalLine);

            var nominalLineIndex = vm.data.nominalLines.length - 1;
            nominalLine.taxCode.nominalLineIndex = nominalLineIndex;

            // request data if the first element(s) already have a value defined (e.g. property reference)
            var previousValue;
            for (var key in nominalLine.accountingElements) {
                if (nominalLine.accountingElements[key].value) {
                    previousValue = nominalLine.accountingElements[key].value;
                } else {
                    requestData(nominalLine.accountingElements[key], nominalLineIndex).then(function (response) {
                        var nextKey = parseInt(key) + 1;

                        nominalLine.accountingElements[key].model = createAccountingElementModel(
                            response,
                            nominalLineIndex,
                            key,
                            nominalLine.accountingElements[nextKey] ? nextKey : null // only set nextKey is there is an AccountingElement after this one
                        );
                    });
                    break;
                }
            }
        }

        function requestData(accountingElement, nominalLineIndex) {
            var data = {};
            if (typeof nominalLineIndex !== 'undefined') {
                data = { accountingElements: JSON.stringify(vm.data.nominalLineData[nominalLineIndex].accountingElements) };
            }

            return apiClient.get(p2pResponse.getLink('self') + '/' + accountingElement.type, data);
        }

        function onSelectAccountingElement(element) {
            vm.data.nominalLineData
                [element.nominalLineIndex]
                .accountingElements
                [element.accountingElementKey]
                .value = element.selected.object.ID;

            if (element.nextKey) {
                requestData(
                    accountingElementsResponse.accountingElements[element.nextKey],
                    element.nominalLineIndex
                ).then(function (response) {
                    var nextKey = parseInt(element.nextKey) + 1;

                    vm.data.nominalLines
                        [element.nominalLineIndex]
                        .accountingElements
                        [element.nextKey]
                        .model = createAccountingElementModel(
                            response,
                            element.nominalLineIndex,
                            element.nextKey,
                            vm.data.nominalLines[element.nominalLineIndex].accountingElements[nextKey] ? nextKey : null
                        );
                });
            }
        }

        function createAccountingElementModel(response, nominalLineIndex, accountingElementKey, nextKey) {
            return {
                nominalLineIndex: nominalLineIndex,
                accountingElementKey: accountingElementKey,
                nextKey: nextKey,
                response: response,
                link: null,
                required: true,
                refreshOnLinkChange: false,
                responseKeyPath: 'data',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'Name',
                onSelect: onSelectAccountingElement,
                onRemove: onRemoveAccountingElement
            };
        }

        function onRemoveAccountingElement(element) {
            var accountingElementCount = vm.data.nominalLines[element.nominalLineIndex].accountingElements.length;
            var startKey = parseInt(element.accountingElementKey) + 1;

            // clear out all AccountingElements after the one that was cleared
            for (var i = startKey; i < accountingElementCount; i++) {
                vm.data.nominalLines[element.nominalLineIndex].accountingElements[i].model = null;
                vm.data.nominalLineData[element.nominalLineIndex].accountingElements[i].value = null;
            }
        }

        function onSelectTaxCode(code) {
            vm.data.nominalLineData
                [code.nominalLineIndex]
                .taxCode = code.selected.object.Code;
        }

        function onRemoveTaxCode(code) {
            vm.data.nominalLineData
                [code.nominalLineIndex]
                .taxCode = null;
        }

        function getIfLengthRequired() {
            if (vm.p2pResponse.isNewFormat) {
                return vm.maxCharacterCount;
            } else {
                return false;
            }
        }

        vm.p2pRatioUpdate = function(index) {
            if (vm.data.nominalLineData[index].ratio) {
                vm.data.nominalLineData[index].ratio =
                    parseFloat(vm.data.nominalLineData[index].ratio.toFixed(2));
            }
        }
    }
})();
