(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsController', CommonJobDetailsController);

    CommonJobDetailsController.$inject = [
        '$scope',
        '$rootScope',
        'lodash',
        'jobResponse',
        'siteResponse',
        'jobRequisitesResponse',
        'apiClient',
        '$state',
        'messenger',
        'confirmationModal',
        'confirmationModalNotes',
        'EditPoNumberModal',
        'userManager',
        'modulesService',
        'recurrenceRulesService',
        'elbDownloadService',
        'canViewJobLinks',
        'config',
        'eventCollectionResponse',
        'jobStatuses',
        'analyticsService'
    ];

    function CommonJobDetailsController(
        $scope,
        $rootScope,
        lodash,
        jobResponse,
        siteResponse,
        jobRequisitesResponse,
        apiClient,
        $state,
        messenger,
        confirmationModal,
        confirmationModalNotes,
        EditPoNumberModal,
        userManager,
        modulesService,
        recurrenceRulesService,
        elbDownloadService,
        canViewJobLinks,
        config,
        eventCollectionResponse,
        jobStatuses,
        analyticsService
    ) {
        var vm = this;
        vm.job = jobResponse;
        vm.config = config;
        vm.siteResponse = siteResponse;
        vm.jobRequisitesExistForJob = false;
        vm.canViewAssetLinks = vm.job._embedded['asset-name'] ? false : true;
        vm.canViewSubAssetLinks = vm.job._embedded['subasset-name'] ? false : true;
        vm.isTenant = userManager.hasPermission('site_permission_tenant')
                          && !userManager.hasPermission('site_permission_helpdesk')
                          && !userManager.hasPermission('site_permission_create_jobs');
        vm.p2p = modulesService.oneOfEnabled(['p2p', 'hexagon_p2p'])
                && ($state.params.p2p || $state.params.p2pAwaitingValue || $state.params.p2pAwaitingPoNumber)
                && vm.job.getLink('p2p');
        vm.p2pPOApprover = userManager.hasPermission('site_permission_submit_p2p_job_po') || userManager.hasPermission('site_permission_p2p');
        vm.loading = false;
        vm.viewJobLinks = canViewJobLinks;
        vm.displayJobValues = vm.job.attributes['value_invoice_number'] && !vm.job.attributes['value_rejected'];
        vm.display4D = !!vm.job.attributes['four_d_url'];

        vm.serviceProvider = vm.job.getLink('serviceprovider') && vm.job.getLink('serviceprovider').title || vm.job.serviceProviderName;

        vm.attributes = vm.job.attributes;
        vm.cisIsSet = typeof vm.job.attributes.value_cis !== 'undefined';

        vm.jobQuoteLinksCollection = [];
        vm.jobLinksCollection = [];
        vm.jobActionLinksCollection = [];

        vm.slaProposal = [];
        vm.extendRequest = false;
        vm.deleteAdditionalOperative = deleteAdditionalOperative;
        vm.requiredDocumentTypesArray = [];
        vm.showSfgWarning = jobStatuses.getAll('nonfinal').some(status => vm.job.status === status);

        vm.statsReady = false;
        vm.formsCounter = vm.filesCounter = vm.subAssetCounter = 0;
        vm.user = userManager.user;

        if (vm.job.requiredDocumentTypes.count) {
            angular.forEach(vm.job.requiredDocumentTypes.documentTypes, function (value) {
                vm.requiredDocumentTypesArray.push(value.name);
            });
        }

        $rootScope.$on('eventsLoaded', function() {
            if (vm.job.status == 'assignment_proposal') {
                var event = eventCollectionResponse.events.find(function (event) {
                    return event.type === 'job_proposal_requested';
                });

                if (event.type == 'job_proposal_requested') {
                    if (event._links['priority']) {
                        vm.slaProposal.priority = event._links['priority'].title;
                    } else {
                        vm.slaProposal.attendanceDueAt = event.attendanceDueAt;
                        vm.slaProposal.completionDueAt = event.completionDueAt;
                    }
                }
            }

            if (vm.job.status == 'extension_pending') {
                var event = eventCollectionResponse.events.find(function (event) {
                    return event.type === 'job_extension_requested';
                });

                if (event && event.type === 'job_extension_requested') {
                    vm.extendRequest = event.completionDueAt;
                }
            }
        });


        if (jobResponse.getLink('links')) {
            apiClient.get(jobResponse.getLink('links')).then(function (response) {
                if (response) {
                    $scope.jobLinksCollection = response;

                    vm.jobQuoteLinksCollection = lodash.filter(response.relationLinks, function(elem) { return elem.type === 'job_quote'; });
                    vm.jobLinksCollection = lodash.filter(response.relationLinks, function(elem) { return elem.type === 'job_job'; });
                    vm.jobActionLinksCollection = lodash.filter(response.relationLinks, function (elem) { return elem.type === 'job_action'; });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        if (vm.isTenant) {
            vm.job.chaseAt = false;
        }

        if (vm.job.recurrenceRule) {
            var frequency = recurrenceRulesService.getRuleFrequencyAsString(vm.job.recurrenceRule.frequency, true);
            vm.job.frequency = vm.job.recurrenceRule.interval + frequency;
        }

        if (jobRequisitesResponse) {
            vm.jobRequisitesExistForJob = jobRequisitesResponse.count > 0;
        }

        analyticsService.pageLoaded();

        vm.isSecondaryOperativesModuleEnabled = modulesService.isEnabled('secondary_operatives');

        vm.resendApprovalRequest = resendApprovalRequest;
        vm.resendAssignmentRequest = resendAssignmentRequest;
        vm.editPoNumber = editPoNumber;
        vm.printJob = printJob;

        function resendApprovalRequest() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.create(vm.job.getLink('resend-approval'), {}).then(function (isSuccess) {
                        if (isSuccess) {
                            return $state.go('.', {}, { reload: $state.get('^') }).then(function () {
                                messenger.success('JOB_APPROVAL_RESENT');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }

        function resendAssignmentRequest() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.create(vm.job.getLink('resend-assignment')).then(function (isSuccess) {
                        if (isSuccess) {
                            return $state.go('^', {}, { reload: $state.get('^') }).then(function () {
                                messenger.success('JOB_ASSIGNMENT_RESENT');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }

        function editPoNumber() {
            return EditPoNumberModal.open(config, jobResponse).result.then(function (response) {
                if (response) {
                    var requestData = {
                        poNumber: response.poNumber,
                        note: response.note
                    }

                    apiClient.update(vm.job.getLink('edit-po-number'), requestData).then(function (response) {
                        if (response) {
                            return $state.go('.', {}, { reload: $state.get('^.^.^') }).then(function () {
                                messenger.success('JOB_PO_NUMBER_UPDATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
                return;
            });
        }

        function deleteAdditionalOperative(operative) {
            return confirmationModalNotes.open({bodyMessage: 'REASON_FOR_OPERATIVE_REMOVAL', required: true}).result.then(function (note) {
                if (note) {
                    var requestData = {
                        _links: {
                            operative: operative._links.self
                        },
                        note: note
                    }

                    apiClient.create(vm.job.getLink('delete-additional-operative'), requestData).then(function (response) {
                        if (response) {
                            return $state.go('.', {}, { reload: $state.get('^.^.^') }).then(function () {
                                messenger.success('JOB_ADDITIONAL_OPERATIVE_REMOVED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }

        function printJob() {
            vm.loading = true;
            apiClient.get(vm.job.getLink('job-sheet')).then(function (response) {
                if (response) {
                    elbDownloadService.download(response);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
                vm.loading = false;
            });
        }

        apiClient.get(jobResponse.getLink('job-stat')).then(function(response) {
            vm.statsReady = true;
            vm.formsCounter = response.formCount;
            vm.filesCounter = response.fileCount;
            vm.subAssetCounter = response.subAssetCount;
        });
    }
})();
