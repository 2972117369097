(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UsersController', UsersController);

    UsersController.$inject = [
        '$state',
        '$stateParams',
        '$filter',
        '$translate',
        'userCollectionResponse',
        'userCategoriesResponse',
        'analyticsService',
        '$scope'
    ];

    function UsersController(
        $state,
        $stateParams,
        $filter,
        $translate,
        userCollectionResponse,
        userCategoriesResponse,
        analyticsService,
        $scope
    ) {
        var vm = this;
        vm.userCollectionResponse = userCollectionResponse;
        vm.users = userCollectionResponse.getData().users;
        vm.pages = userCollectionResponse.getData().pages;
        vm.stateParams = $stateParams;
        vm.order = order;
        vm.search = search;

        var deregisterWatch = $scope.$watch('vm.users', function(value) {
            analyticsService.pageLoaded();
            deregisterWatch();
        });

        var statusOptions = [
            {
                title: $filter('translate')('ALL'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_DRAFT'),
                value: 'draft'
            },
            {
                title: $filter('translate')('STATUS_TRAINING'),
                value: 'training'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        var userCategories = Object.entries(userCategoriesResponse)
            .filter(function(entry) {
                return typeof entry[1] !== 'function';
            })
            .map(function(entry) {
                return {
                    title: $translate.instant('USER_CATEGORY_' + entry[1].toUpperCase()),
                    value: entry[0]
                };
            });

        userCategories.unshift({
            value: '-1',
            title: $translate.instant('BLANK_OPTION'),
            optionStyle: {'font-style': 'italic'},
            displayedValueStyle: {'font-style': 'italic', 'padding-right': '1px'}
        });

        vm.criteria = {
            adminUserId: { type: 'number', title: 'ID', value: $stateParams.adminUserId ? parseInt($stateParams.adminUserId, 10) : null, min: 1 },
            userName: { type: 'text', title: 'NAME', value: $stateParams.userName },
            userCategory: { type: 'options', multiple: true, title: 'USER_CATEGORY', value: $stateParams.userCategory, options: userCategories, clearValue: null },
            userUsername: { type: 'text', title: 'USERNAME', value: $stateParams.userUsername },
            userEmail: { type: 'text', title: 'EMAIL', value: $stateParams.userEmail },
            userServiceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.userServiceProviderName },
            userStatus: { type: 'options', title: 'STATUS', value: $stateParams.userStatus, options: statusOptions, clearValue: null },
            userPhone: { type: 'phone', title: 'TELEPHONE', value: $stateParams.userPhone },
        };  

        /**
         * Search users
         */
        function search(params) {
            var override = {
                page: '1'
            };

            $state.go('.', params, override, { reload: $state.current });
        }

        /**
         * Order users
         */
        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
