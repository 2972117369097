(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionAddDetailsController', CommonActionAddDetailsController);

    CommonActionAddDetailsController.$inject = ['$rootScope', '$scope', '$state', 'crudService', 'confirmationModal', 'actionTypeCollectionResponse', 'priorityCollectionResponse', 'actionCollectionResponse', 'statutoryTypeCollectionResponse', 'locationCollectionResponse', 'messenger', 'spillageResponseLink', 'priorityService', '$translate', 'elbSettings', 'lodash', 'assetCollectionResponse', '$stateParams'];

    function CommonActionAddDetailsController($rootScope, $scope, $state, crudService, confirmationModal, actionTypeCollectionResponse, priorityCollectionResponse, actionCollectionResponse, statutoryTypeCollectionResponse, locationCollectionResponse, messenger, spillageResponseLink, priorityService, $translate, elbSettings, lodash, assetCollectionResponse, $stateParams) {
        $scope.form.$setPristine();
        $rootScope.forceDirtyConfirm = true;

        var vm = $scope.vm;
        vm.canNext = false;
        vm.canSubmit = true;

        vm.data.files = {
            links: []
        };

        vm.isStatutory = false;
        vm.fileRequired = false;
        vm.isRaisedFromSpillage = !!spillageResponseLink;
        vm.title = 'ACTION_ADD_DETAILS';

        vm.actionTypeCollectionResponse = actionTypeCollectionResponse;
        vm.priorityCollectionResponse = priorityCollectionResponse;
        vm.statutoryTypeCollectionResponse = statutoryTypeCollectionResponse;
        vm.locationCollectionResponse = locationCollectionResponse;
        vm.assets = assetCollectionResponse;

        vm.submit = submitAction;

        var priorities  = priorityService.addExtraPriorities(priorityCollectionResponse, ['byPlannedDate']);

        lodash.each(priorities.priorities, function (priority) {
            if (priority.name === 'By Planned Date') {
                var colour = elbSettings.getSetting('general_non_priority_colour').value;

                if (typeof colour === 'string') {
                    priority.colour = colour.replace('#','');
                }
            }
        });

        vm.selectedPriorityModel = {
            response: priorities,
            link: null,
            linkParameters: {active: true, entityTypes: 'entity_type_all,entity_type_action'},
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        vm.data.assets = {
            asset: null,
            subasset: null
        };

        if (vm.assets) {
            vm.selectedAssetModel = {
                required: false
            };
        }

        vm.selectedActionTypeModel = {
            response: actionTypeCollectionResponse,
            link: null,
            linkParameters: {active: true},
            required: false,
            responseKeyPath: 'actiontypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            searchKey: 'summary',
            isHierarchical: true,
            onSelect: changeActionType,
            onRemove: changeActionType
        };

        vm.selectedStatutoryTypeModel = {
            response: statutoryTypeCollectionResponse,
            link: null,
            required: true,
            responseKeyPath: 'statutorytypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeStatutoryType,
            onRemove: changeStatutoryType
        };

        if (vm.redirectToStart) {
            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('site'), {}, { reload: false });
        }

        if ($stateParams.sourceJobAsset) {
            vm.data.assets.asset = {href: $stateParams.sourceJobAsset.decode()};

            if ($stateParams.sourceJobSubasset) {
                vm.data.assets.subasset = {href: $stateParams.sourceJobSubasset.decode()};
            }
        }

        function changePriority() {
            if (vm.selectedPriorityModel.selected && vm.selectedPriorityModel.selected.object) {
                vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');

                if (vm.data.isByPlannedDate) {
                    delete vm.data._links.priority;
                }

                if (vm.selectedPriorityModel.selected.object.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: vm.selectedPriorityModel.selected.object.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }
            } else {
                delete vm.data._links.priority;
            }
        }

        function changeStatutoryType() {
            if (vm.selectedStatutoryTypeModel.selected && vm.selectedStatutoryTypeModel.selected.object) {
                vm.data._links.statutorytype = {href: vm.selectedStatutoryTypeModel.selected.href};
            } else {
                delete vm.data._links.statutorytype;
            }
        }

        function changeActionType() {
            if (vm.selectedActionTypeModel.selected && vm.selectedActionTypeModel.selected.object) {
                vm.data._links.actiontype = {href: vm.selectedActionTypeModel.selected.href};
                vm.fileRequired = vm.selectedActionTypeModel.selected.object.systemType === 2; // 2 = RESERVED_TYPE_PAPERWORK_FOR_REVIEW
            } else {
                delete vm.data._links.actiontype;
                vm.fileRequired = false;
            }
        }

        function submitAction() {
            
            if(vm.data.files.links.length === 0 && vm.fileRequired) {
                return;
            }

            if (spillageResponseLink) {
                vm.data._links.actiontype = {href: actionTypeCollectionResponse.actiontypes[0].getLink('self')};
            }

            if (vm.data.assets) {
                if (vm.data.assets.asset) {
                    vm.data._links.asset = vm.data.assets.asset;
                }

                if (vm.data.assets.subasset) {
                    vm.data._links.subasset = vm.data.assets.subasset;
                }
            }

            if ($stateParams.isRemedial !== null) {
                vm.data.isRemedial = $stateParams.isRemedial;
            }

            if ($stateParams.sourceJob !== null) {
                vm.data._links.sourceJob = { href: $stateParams.sourceJob.decode() };
            }

            return crudService.create(
                vm.config.createLink,
                vm.data,
                null,
                null,
                'ACTION',
                true
            ).then(function(response) {
                if ($stateParams.isRemedial) {
                    var successRoute = '', params = {};

                    if ($state.current.name.indexOf('dashboard.user.sites.list') > -1) {
                        successRoute = 'dashboard.user.sites.list.details.jobs.list.details.remedials';
                        params.siteResource = $stateParams.siteResponse;
                        params.jobResource = $stateParams.sourceJob;
                    } else {
                        successRoute = 'dashboard.user.jobs.list.details.remedials';
                        params.resource = $stateParams.sourceJob;
                    }

                    $state.go(successRoute, params, {reload: successRoute}).then(function() {
                        messenger.success('ACTION_CREATED');
                    });
                } else {
                    var listState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5);
                    $state.go(listState + '.details.workflow', {actionResource: response.getLink('self').encode()}, {reload: listState}).then(function() {
                        messenger.success('ACTION_CREATED');
                    });
                }
            }, function() {
                messenger.error('REQUEST_ERROR');
            });
        }

        $scope.$watch('vm.isStatutory', function(newValue, oldValue) {
            if (newValue !== oldValue && !newValue) {
                delete vm.data._links.statutorytype;
            }
        });
    }
})();
