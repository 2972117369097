(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedAuditFiltersModalController', advancedAuditFiltersModalController);

    advancedAuditFiltersModalController.$inject = [
        '$state',
        '$stateParams',
        '$scope',
        '$uibModalInstance',
        '$translate',
        '$rootScope',
        'lodash',
        'config',
        'serviceProviderResponse',
        'operativeCollectionResponse'
    ];

    function advancedAuditFiltersModalController(
        $state,
        $stateParams,
        $scope,
        $uibModalInstance,
        $translate,
        $rootScope,
        lodash,
        config,
        serviceProviderResponse,
        operativeCollectionResponse
    ) {

        var vm = this;

        vm.close = close;
        vm.update = update;
        vm.saveParams = saveParams;
        vm.config = config;
        vm.type = $stateParams.auditType;
        var statusOptions = [];
        var defaultValues = [];

        if (vm.type === 'audit') {
            statusOptions = [
                {
                    value: 'audit_draft',
                    title: $translate.instant('AUDIT_DRAFT')
                },
                {
                    title: $translate.instant('AUDIT_CREATED'),
                    value: 'audit_created'
                },
                {
                    title: $translate.instant('AUDIT_STARTED'),
                    value: 'audit_started'
                },
                {
                    title: $translate.instant('AUDIT_COMPLETED'),
                    value: 'audit_completed'
                },
                {
                    title: $translate.instant('AUDIT_CANCELLED'),
                    value: 'audit_cancelled'
                }
            ];

            defaultValues = ['audit_draft', 'audit_created', 'audit_started'];

            if ($stateParams.auditStatus === 'all') {
                $stateParams.auditStatus = ['audit_draft', 'audit_created', 'audit_started'];
            }

        } else if (vm.type === 'template') {
            statusOptions = [
                {
                    value: 'all',
                    title: $translate.instant('ALL')
                },
                {
                    title: $translate.instant('AUDIT_TEMPLATE_ACTIVE'),
                    value: 'audit_template_active'
                },
                {
                    title: $translate.instant('AUDIT_TEMPLATE_INACTIVE'),
                    value: 'audit_template_inactive'
                },
                {
                    title: $translate.instant('AUDIT_DRAFT'),
                    value: 'audit_draft'
                }
            ];

            defaultValues = ['audit_template_active'];
        } else {
            statusOptions = [
                {
                    value: 'all',
                    title: $translate.instant('ALL')
                },
                {
                    title: $translate.instant('AUDIT_SCHEDULE_ACTIVE'),
                    value: 'audit_schedule_active'
                },
                {
                    title: $translate.instant('AUDIT_SCHEDULE_INACTIVE'),
                    value: 'audit_schedule_inactive'
                }
            ];

            defaultValues = ['audit_schedule_active'];
        }

        vm.data = {};
        vm.advancedCriteria = {};

        if (!Array.isArray($stateParams.auditStatus)) {
            $stateParams.auditStatus = [$stateParams.auditStatus];
        }

        vm.advancedCriteria = {
            auditId: { type: 'number', value: $stateParams.auditId ? parseInt($stateParams.auditId, 10) : null, title: 'ID', min: 1 },
            auditSummary: { type: 'text', class: 'full-width', title: 'SUMMARY', value: $stateParams.auditSummary },
            auditReference: { type: 'text', class: 'full-width', title: 'REFERENCE', value: $stateParams.auditReference },
            scheduleName: { type: 'text', class: 'full-width', title: 'SCHEDULE_NAME', value: $stateParams.scheduleName },
            auditStatus: {
                type: 'options',
                title: 'STATUS',
                value: $stateParams.auditStatus,
                options: statusOptions,
                multiple: true,
                isClear: lodash.isEqual($stateParams.auditStatus, defaultValues),
                clearValue: defaultValues
            },
            createdAt: {
                type: 'date',
                title: 'CREATED_AT_START',
                value: (typeof $stateParams.createdAt !== 'undefined' && $stateParams.createdAt !== null) ? moment(new Date($stateParams.createdAt)) : null
            },
            createdAtEnd: {
                type: 'date',
                title: 'CREATED_AT_END',
                end: true,
                value: (typeof $stateParams.createdAtEnd !== 'undefined' && $stateParams.createdAtEnd !== null) ? moment(new Date($stateParams.createdAtEnd)) : null
            },
            completionDueAt: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_START',
                value: (typeof $stateParams.completionDueAt !== 'undefined' && $stateParams.completionDueAt !== null) ? moment(new Date($stateParams.completionDueAt)) : null
            },
            completionDueAtEnd: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_END',
                end: true,
                value: (typeof $stateParams.completionDueAtEnd !== 'undefined' && $stateParams.completionDueAtEnd !== null) ? moment(new Date($stateParams.completionDueAtEnd)) : null
            },
            completedAt: {
                type: 'date',
                title: 'COMPLETED_AT_START',
                value: (typeof $stateParams.completedAt !== 'undefined' && $stateParams.completedAt !== null) ? moment(new Date($stateParams.completedAt)) : null
            },
            completedAtEnd: {
                type: 'date',
                title: 'COMPLETED_AT_END',
                end: true,
                value: (typeof $stateParams.completedAtEnd !== 'undefined' && $stateParams.completedAtEnd !== null) ? moment(new Date($stateParams.completedAtEnd)) : null
            },
            generatedForDate: {
                type: 'date',
                title: 'AUDITS_GENERATED_FOR_DATE_START',
                value: (typeof $stateParams.generatedForDate !== 'undefined' && $stateParams.generatedForDate !== null) ? moment(new Date($stateParams.generatedForDate)) : null
            },
            generatedForDateEnd: {
                type: 'date',
                title: 'AUDITS_GENERATED_FOR_DATE_END',
                end: true,
                value: (typeof $stateParams.generatedForDateEnd !== 'undefined' && $stateParams.generatedForDateEnd !== null) ? moment(new Date($stateParams.generatedForDateEnd)) : null
            }
        };

        if ($stateParams.auditType.toUpperCase() === 'SCHEDULE') {
            vm.advancedCriteria.nextOccurrence = {
                type: 'date',
                title: 'NEXT_OCCURENCE_START',
                value: (typeof $stateParams.nextOccurrence !== 'undefined' && $stateParams.nextOccurrence !== null) ? moment(new Date($stateParams.nextOccurrence)) : null
            };
            vm.advancedCriteria.nextOccurrenceEnd = {
                type: 'date',
                title: 'NEXT_OCCURENCE_END',
                end: true,
                value: (typeof $stateParams.nextOccurrenceEnd !== 'undefined' && $stateParams.nextOccurrenceEnd !== null) ? moment(new Date($stateParams.nextOccurrenceEnd)) : null
            };

            delete vm.advancedCriteria.completionDueAt;
            delete vm.advancedCriteria.completionDueAtEnd;
        }

        if (serviceProviderResponse && serviceProviderResponse.serviceproviders && serviceProviderResponse.serviceproviders.length !== 0) {
            var selected = $stateParams.serviceProvider ? {
                title: JSON.parse($stateParams.serviceProvider).n,
                value: JSON.parse($stateParams.serviceProvider).v
            } : null;

            vm.advancedCriteria.serviceProvider = {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                value: $stateParams.serviceProvider,
                options: {
                    mapValue: 'serviceProviderName',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: serviceProviderResponse,
                    responseKeyPath: 'serviceproviders',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selected
                }
            };
        }

        if (operativeCollectionResponse && operativeCollectionResponse.operatives && operativeCollectionResponse.count > 0) {
            var selected = $stateParams.operative ? {
                title: JSON.parse($stateParams.operative).n,
                value: JSON.parse($stateParams.operative).v
            } : null;

            vm.operativeSelect = {
                response : operativeCollectionResponse,
                link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                itemValuePath: '_links.user.id',
                itemTitlePath: '_links.user.name',
                responseKeyPath: 'operatives',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                selected: selected
            };

            vm.advancedCriteria.operative = {
                type: 'jsonselectwidget',
                title: 'OPERATIVE',
                value: JSON.parse($stateParams.operative),
                options: vm.operativeSelect
            };
        }

        var params = {};

        function saveParams (selectModel) {
            if (selectModel.callback) {
                selectModel.callback(selectModel.selected ? selectModel.selected.object.id : null);
            }

            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    if (selectModel.itemHrefPath === '_links.self.href') {
                        var itemIdPath = selectModel.itemIdPath ? selectModel.itemIdPath : 'id';
                        params[selectModel.mapValue] = selectModel.selected.object[itemIdPath];
                    } else {
                        params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemIdPath);
                    }
                }
            } else {
                params = selectModel;
            }
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                $stateParams.page = '1';
            }

            var override = {
                auditPage: 1,
                auditType: vm.type
            };

            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params, override), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
