(function () {
    'use strict';

    angular.module('elogbooks.user.tasks').controller('UserSeriesModalUpdateController', UserSeriesModalUpdateController);

    function UserSeriesModalUpdateController($rootScope, $scope, apiClient, $uibModalInstance, $state, moment, $stateParams, messenger, requestData, eventData, recurrenceRulesService, title) {
        var vm = this;
        vm.requestData = requestData;
        vm.update = updateAction;
        vm.requestData = requestData;
        vm.cancel = cancel;
        vm.type = eventData.droppedOnEvent ? 'all' : 'single';
        vm.taskStatus = eventData.status;
        vm.endDate = null;
        vm.startDate = requestData.date.toDate();
        vm.eventData = eventData;
        vm.lastYear = moment().year() - 1;
        vm.minDate = '01-01-' + vm.lastYear;
        vm.startDatePickerOptions = {
            minDate: new Date(vm.minDate),
            maxDate: null
        };

        vm.endDatePickerOptions = {
            minDate: vm.startDate ? new Date(vm.startDate) : null
        };

        vm.showOriginalDate = !!vm.startDate;
        vm.startDatePrefilled = vm.startDate;

        let originalDate;
        if (vm.requestData.ruleData) {
            originalDate = vm.requestData.ruleData.from;
        }

        let minDateMoment = moment().set({
            year: vm.lastYear,
            date: 1,
            month: 1
        });

        if (originalDate && minDateMoment.isAfter(moment(originalDate))) {
            vm.minDate = moment(originalDate).format('DD-MM-yyyy');
        }

        $scope.$watch('vm.startDate', function(newDate){
            if (newDate) {
                if (moment(newDate).isBefore(vm.minDate, 'day')) {
                    messenger.error('TASK_START_DATE_WARNING', { lastYear : vm.lastYear});
                }
                vm.endDatePickerOptions.minDate =  new Date(newDate);
                if (vm.endDate && moment(newDate).isAfter(vm.endDate, 'day')) {
                    vm.startDate = null;
                    messenger.error('END_DATE_ERROR', { date :  vm.startDate});
                }
            } else {
                vm.endDatePickerOptions.minDate = null;
            }
        });

        $scope.$watch('vm.endDate', function(newDate){
            if (newDate) {
                vm.startDatePickerOptions.maxDate = new Date(newDate);
                if (vm.startDate && moment(newDate).isBefore(vm.startDate, 'day')) {
                    vm.endDate = null;
                    messenger.error('START_DATE_ERROR', { date : vm.endDate});
                }
            } else {
                vm.startDatePickerOptions.maxDate = null;
            }
        });

        vm.title = title;
        vm.frequency = requestData.ruleData ? requestData.interval + recurrenceRulesService.getRuleFrequencyAsString(requestData.frequency, true) : null;
        vm.rule = requestData.ruleData ? recurrenceRulesService.getRuleDescription(requestData.ruleData) : null;
        var rule = {
            frequency:  0,
            interval:  0,
            byDay:  [[0,1]]
        }
        vm.datePickerOptionsStartDate = { minDate: vm.minDate };

        // This includes a hack using the originalDate to determine if the approved event being moved is a single or from a rule.
        vm.showAllFrequencies = vm.eventData.status == 3 && !eventData.taskEvent.originalDate || [10,12,13,14].indexOf(vm.eventData.status) > -1;

        function cancel() {
            $uibModalInstance.close(false);
        }

        function updateAction(type) {
            if ('single' === type) {
                updateEvents(eventData.url, type);
            } else if ('all' === type) {
                vm.requestData.rules = [
                    {
                       "frequency": rule.frequency,
                        "interval": rule.interval,
                        "_links": {},
                        "byDay": rule.byDay,
                        "description": "test post"
                    }
                ];
                updateEvents(eventData.taskObj.getLink('series'), type);
            }

            $uibModalInstance.close(true);
        }

        function updateEvents(url, type) {
            if (type == 'all') {
                vm.requestData.endDate = vm.endDate;
                vm.requestData.date = vm.startDate;
            }

            if (vm.endDate !== null && vm.startDate !== null && (vm.startDate > vm.endDate) && type == 'all') {
                $state.go('.', $stateParams, {reload: $state.current}).then(function () {
                    messenger.error('END_DATE_MUST_BE_AFTER_START_DATE');
                });

                return;
            }

            apiClient.update(url, vm.requestData).then(function (response) {
                if (response) {
                    eventData.planner[eventData.taskId][eventData.weekTo] = response;
                    delete eventData.planner[eventData.taskId][eventData.weekFrom];

                    apiClient.get(eventData.taskObj.getLink('self'), {datagroup: 'planner', year: $state.params.taskYear}).then(function (response) {
                        $rootScope.$emit('rebuildPlanner', {task: response, index: eventData.taskIndex});
                    });

                    setTimeout(function () {
                        eventData.element.removeClass('dropped');
                    }, 500);
                } else {
                    $state.go('.', $stateParams, {reload: $state.current}).then(function () {
                        messenger.error('REQUEST_ERROR');
                    });
                }
            });
        }
    }
})();
